import { useEffect, useRef, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../../store/error-slice';
import { RepoFactory } from '../../../baseRepository/Factory';
import { Timeline, TimelineAlignType } from 'vis-timeline';
import { DataSet } from 'vis-data';
import { UserSatus, TimelineItem, TimelineGroup } from '../types/type';
import { Team, UserRole, RepoType } from '../../../types/sharedTypes';
import In from '../../../assets/image/Icons/In.svg';
import Out from '../../../assets/image/Icons/Out.svg';
import { useTranslation } from '../../../providers/locale-provider';
import minus from '../../../assets/image/Icons/minus.svg';
import { RootState } from '../../../store';
import { capitalizeFirstOfEach } from '../../../core/helpers/utils';
import { setShortText, capitalizeWords, setvalidname } from '../../../core/helpers/utils';
import { getCurrentYearDate } from '../../../core/helpers/FormatDate';

export const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);
export const TeamRepository = () => RepoFactory.get(RepoType.Teams);
export const LeaveRepository = () => RepoFactory.get(RepoType.Leave);
const profileRepository = () => RepoFactory.get(RepoType.Profile);

interface useLeaveTimelineProps {
  userRole: string;
  selectedTeam: Team[];
  order: string;
  onAvatarClick: (src: string, fullname: string | null, email: string, isActive: boolean) => void;
  onAttendanceClick: (itemId: number, user: UserSatus) => void;
  debouncedSearchTerm: string;
}

export const InitialUsersLeaveStatus = [
  {
    id: 0,
    username: '',
    email: '',
    leaves: [],
    name: '',
    last_name: '',
    teams: [],
    punchStatus: '',
    photo: '',
    userAttandance: [],
  },
];

interface UserData {
  name: string;
  id: number;
  status: boolean;
  email: string;
  photo: string;
  last_name: string;
}

export const useLeaveTimeline = (props: useLeaveTimelineProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const attendance = useSelector((state: RootState) => state.attendanceSlice);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserData>();
  const [usersLeaveStatus, setUsersLeaveStatus] = useState<UserSatus[]>(InitialUsersLeaveStatus);
  const [timeline, setTimeline] = useState<Timeline | null>(null);
  const [months, setMonths] = useState<string>();
  const date: Date = new Date();
  date.setHours(0, 0, 0, 0);
  const first: Date = new Date();
  first.setDate(date.getDate() - date.getDay());
  first.setHours(0, 0, 0, 0);
  const last: Date = new Date();
  last.setDate(date.getDate() - date.getDay() + 7);
  last.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState<Date>(first);
  const [endDate, setEndDate] = useState<Date>(last);
  const timelineRef = useRef<HTMLDivElement>(null);
  let timelineTemp: Timeline | null = null;

  const DAYS_IN_WEEK = 7;

  useEffect(() => {
    attendance && createTimeline();
  }, [attendance]);

  useEffect(() => {
    // // Event handler function
    const handleClick = function (event: any) {
      var timelineProps = timeline && timeline.getEventProperties(event);
      if (timelineProps?.item && timelineProps.group) {
        const user = usersLeaveStatus.find((user) => user.id === timelineProps?.group);
        if (user) {
          const attandanceData = user.userAttandance.find((attandance) => attandance.id === timelineProps?.item);
          if (attandanceData?.check_out_datetime) props.onAttendanceClick(+timelineProps.item, user);
        }
      }
    };

    const element = timelineRef.current;

    if (element) {
      element.addEventListener('click', handleClick);
    }

    // Cleanup the event listener on unmount
    return () => {
      if (element) {
        element.removeEventListener('click', handleClick);
      }
    };
  }, [timeline, usersLeaveStatus]);

  const handleApiError = (error: AxiosError) => {
    setLoading(false);
    if (error instanceof AxiosError) {
      dispatch(errorActions.setHasError(true));
      dispatch(errorActions.setError(error.response?.data || 'An error occurred'));
    }
  };

  const getLeavesStatus = async () => {
    setLoading(true);

    const teamIds = props.selectedTeam.map((item) => item.id).join(',');

    let param = {
      start: +startDate / 1000,
      end: +endDate / 1000,
      teamIds: teamIds,
      sortBy: 'last_name',
      order: props.order,
      search: props.debouncedSearchTerm,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    try {
      let res;
      if (props.userRole === UserRole.REGULAR && teamIds) {
        res = await LeaveRepository().getLeaveStatusEmployee(param);
      } else if (props.userRole === UserRole.MANAGER && teamIds) {
        res = await LeaveRepository().getLeaveStatus(param);
      }
      setUsersLeaveStatus(res.data.result);
    } catch (error: any) {
      handleApiError(error);
    }
    setLoading(false);
  };

  const getProfile = () => {
    setLoading(true);

    profileRepository()
      .getProfile()
      .then((res: AxiosResponse<any>) => {
        const userRes = res.data.result;
        const user = {
          name: userRes.name,
          last_name: userRes.last_name,
          id: userRes.id,
          status: userRes.status,
          email: userRes.email,
          photo: userRes.photo,
        };
        setUserData(user);
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getMonths = () => {
    let months: string = '';

    //get the name of month of first day of current week like november
    let firstMonth = startDate.toLocaleString('default', { month: 'long' });
    //get the name of month of last day of current week like december
    let lastMonth = endDate.toLocaleString('default', { month: 'long' });

    if (firstMonth === lastMonth) months = firstMonth;
    else months = firstMonth + ' - ' + lastMonth;

    setMonths(months);
  };

  const generateLeaveDetails = (item: any, itemType: string) => {
    let hasEnd = true;
    let content = '';
    let startItem = new Date(+item.start_datetime * 1000);
    let endItem = new Date(+item.end_datetime * 1000);
    if (itemType === 'attendance') {
      startItem = new Date(+item.check_in_datetime * 1000);
      endItem = new Date(+item.check_out_datetime * 1000);
      if (endItem.getTime() === 0) {
        endItem = new Date();
        hasEnd = false;
      }
    }

    let title = '';

    const formatTime = (date: Date) =>
      `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    if (itemType === 'attendance') {
      const isEdited = item.edited === 1;
      content = hasEnd ? `${formatTime(startItem)} - ${formatTime(endItem)}` : `${formatTime(startItem)} - ***`;
      content = isEdited ? content + ' *' : content;
      title = 'attendance';
      startItem.setHours(1, 0, 0, 0);
      endItem.setHours(23, 0, 0, 0);
    } else if (itemType === 'hourly') {
      content = `${formatTime(startItem)} - ${formatTime(endItem)}`;
      title = 'Hourly Time Off';
      startItem.setHours(1, 0, 0, 0);
      endItem.setHours(23, 0, 0, 0);
    } else {
      content = 'Time Off';
      title = 'Daily Time Off';
      startItem.setHours(1, 0, 0, 0);
      endItem.setHours(23, 0, 0, 0);
    }

    return { content, startItem, endItem, title };
  };

  const createTimeline = () => {
    // Options for the vis Timeline
    const options = {
      editable: false,
      orientation: 'top',
      stack: true,
      verticalScroll: true,
      horizontalScroll: false,
      zoomMax: 1000 * 60 * 60 * 24 * 31 * 1, // About 1 months
      zoomMin: 1000 * 60 * 60 * 24 * 7, // About 7 day
      preferZoom: true,
      start: startDate,
      end: endDate,
      align: 'center' as TimelineAlignType,
      // locale: 'fa',
      // dir:"rtl",
      format: {
        minorLabels: {
          minute: 'h:mma',
          hour: 'ha',
        },
        majorLabels: {
          day: 'ddd DD MMM',
          week: 'MMM DD',
          month: 'jMMM',
        },
      },
      zoomable: false,
      moveable: false,
      minHeight: '157px',
      showCurrentTime: false,
      maxHeight: '530px',
    };
    // Todo Leave status
    const allUsersHaveNoLeaves = usersLeaveStatus.every((user) => user.leaves.length === 0);

    if (timelineRef.current) {
      if (timelineRef.current) {
        // if (allUsersHaveNoLeaves) {
        //   handleNoLeavesCase(options);
        // } else {
        handleLeavesCase(options);
        // }
      }
    }
  };

  const handleNoLeavesCase = (options: any) => {
    const timelineGroups: TimelineGroup[] = [
      {
        id: 0,
        content: '',
        style: 'background-color: white;',
      },
    ];

    const content = t9n.no_off_week_text;
    const startItem: Date = new Date(+startDate);
    const endItem: Date = new Date(+endDate);

    startItem.setHours(1, 0, 0, 0);
    endItem.setDate(endItem.getDate() - 1);
    endItem.setHours(23, 0, 0, 0);

    const item_temp: TimelineItem = {
      type: 'background',
      id: 0,
      group: 0,
      content: content,
      start: startItem,
      end: endItem,
      style: 'background-color: white; font-weight: 900; font-size:14pt; min-height: 100px; color: black;',
    };

    const timelineItems: TimelineItem[] = [item_temp];

    updateTimeline(options, timelineGroups, timelineItems);
  };

  const handleLeavesCase = (options: any) => {
    const timelineGroups: TimelineGroup[] = [];
    const timelineItems: TimelineItem[] = [];

    const processUserDetails = (
      name: string,
      lastName: string | null,
      email: string,
      photo: string,
      punchStatus: string
    ) => {
      const isFullName = name && lastName;
      const fullName = name + ' ' + lastName;
      const shortName = capitalizeWords(setvalidname(isFullName, name, lastName, fullName));

      // Creating HTML element for user details
      const htmlelement = document.createElement('div');

      const capitalizeFirst = name ? capitalizeFirstOfEach(name)[0] : '-';
      const title = isFullName ? capitalizeWords(fullName) : capitalizeWords(name ? name : lastName);

      htmlelement.innerHTML = `
      <div style="position:relative; top: 10px">
        <div style="display: flex; align-items: center; width: 200px; gap: 4px;">
          <div style="display: flex; position: relative;">
          ${
            photo
              ? `<div id="photo-container" style="cursor: pointer;">
                  <img src="${'https://iclock.online/api/' + photo}" alt="avatar"
                  style="width: 40px; height: 40px; border-radius: 50%;" id="avatar-img" />
                </div>`
              : `<div style="display: flex; align-items: center; justify-content: center;
                  width: 40px; height: 40px; border-radius: 50%; background-color:#D3D3D3; color: white;">
                  <span style="font-weight: 900;">${capitalizeFirst}</span>
                </div>`
          }
            <img src=${
              punchStatus === 'In' ? In : Out
            } alt="status" style="align-self: flex-end; position: absolute; bottom: 0; right: 0; border: 2px solid white; border-radius: 50%;"/>
          </div>  
          <span title="${title}" style="font-weight: bold; font-family: Montserrat; font-size: 14px; line-height: 20px;">
            ${shortName ?? `<img src=${minus} alt="minus" />`}
          </span>
        </div>
        <div style="height:20px"></div>
        <div style="font-family: Montserrat; font-size: 12px; font-weight: 400; line-height: 20px;">
          ${email ? email : `<img src=${minus} alt="minus" />`}
        </div>
      </div>
    `;

      htmlelement.appendChild(document.createElement('br'));

      // Attach the onclick event separately
      if (photo) {
        const avatarImg = htmlelement.querySelector('#avatar-img') as HTMLElement;
        if (avatarImg) {
          avatarImg.onclick = () => {
            props.onAvatarClick('https://iclock.online/api/' + photo, fullName, email, punchStatus === 'In');
          };
        }
      }

      return htmlelement;
    };

    let timelineItemCounter: number = 100000;

    for (const user of usersLeaveStatus) {
      if (!user.teams.length && userData && attendance) {
        timelineGroups.push({
          id: userData.id,
          content: processUserDetails(
            userData.name,
            userData.last_name,
            userData.email,
            userData.photo,
            attendance.status
          ),
        });
      } else {
        timelineGroups.push({
          id: user.id,
          content: processUserDetails(user.name, user.last_name, user.email, user.photo, user.punchStatus),
        });
      }

      const leaveItems: TimelineItem[] = user.leaves.map((item: any) => {
        const isHourly = item.type === 'hourly';
        const { content, startItem, endItem, title } = generateLeaveDetails(item, item.type);

        const itemStyle = 'background-color: #EA5455; height: 32px; border-radius: 4px; color: #fff;';

        return {
          id: timelineItemCounter++,
          group: user.id,
          content: content,
          title: title,
          start: startItem,
          end: endItem,
          style: itemStyle,
        };
      });

      timelineItems.push(...leaveItems);

      if (props.userRole === UserRole.MANAGER && user.userAttandance) {
        if (user.userAttandance.length) {
          const attendanceItems: TimelineItem[] = user.userAttandance.map((item: any, index) => {
            const { content, startItem, endItem, title } = generateLeaveDetails(item, 'attendance');
            const isEdited = item.edited === 1;
            const isMoreThan10Hours = isEventMoreThan10Hours(item.check_in_datetime, item.check_out_datetime)
            const itemStyle =
              `${isMoreThan10Hours ? 'background-color: #014D9F;' :'background-color: #007AFF;'}` +
              'height: 32px; border-radius: 4px; color: #fff;';

            return {
              id: item.id,
              group: user.id,
              content: content,
              title: title,
              start: startItem,
              end: endItem,
              style: itemStyle,
            };
          });
          timelineItems.push(...attendanceItems);
        }
      }
    }

    updateTimeline(options, timelineGroups, timelineItems);
  };

  const updateTimeline = (options: any, timelineGroups: TimelineGroup[], timelineItems: TimelineItem[]) => {
    const groups = new DataSet<TimelineGroup>(timelineGroups);
    const items = new DataSet<TimelineItem>(timelineItems);

    // Create a Timeline
    if (timeline) {
      timeline.setData({ groups, items });
      timeline.setWindow(startDate, endDate);
    } else {
      timelineTemp?.destroy();
      if (timelineRef.current) timelineTemp = new Timeline(timelineRef.current, items, groups, options);
      setTimeline(timelineTemp);
    }
  };

  const isEventMoreThan10Hours = (startItem: string, endItem: string) => {
    const TEN_HOURS_IN_MILLISECONDS = 10 * 60 * 60 * 1000;
    const checkInMilliseconds = +startItem * 1000;
    const checkOutMilliseconds = +endItem * 1000;

    const difference = checkOutMilliseconds - checkInMilliseconds;

    if (difference >= TEN_HOURS_IN_MILLISECONDS) {
      return true;
    } else {
      return false;
    }
  }

  const updateWeek = (direction: 'next' | 'prev') => {
    const offset = direction === 'next' ? DAYS_IN_WEEK : -DAYS_IN_WEEK;
    const newStartDate = new Date(startDate.setDate(startDate.getDate() + offset));
    const newEndDate = new Date(endDate.setDate(endDate.getDate() + offset));
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    timeline?.setWindow(newStartDate, newEndDate);
  };

  useEffect(() => {
    if (props.selectedTeam || props.order) {
      (async () => {
        await getLeavesStatus();
      })();
    }
  }, [props.selectedTeam, props.order, startDate, props.debouncedSearchTerm]);

  useEffect(() => {
    getProfile();
  }, []);

  return {
    loading,
    usersLeaveStatus,
    months,
    startDate,
    timelineRef,
    updateWeek,
    createTimeline,
    getMonths,
    userData,
  };
};