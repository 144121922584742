import { Calendar as BigCalendar, CalendarProps, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import ToolBar from '../components/ToolBar/toolbar';
import EventInfo from '../components/EventInfo/EventInfo';
import MonthHeader from '../components/MonthHeader/MonthHeader';
import classes from './style/Calendar.module.scss';
import { isDefined } from '../../../core/helpers/utils';
import React from 'react';

interface Props {
  status: string;
}

const localizer = momentLocalizer(moment);

export default function Calendar(props: Omit<CalendarProps, 'localizer'> & Props) {
  const CustomDateHeader: React.FC<{ label: string; date: Date }> = ({ label, date }) => {
    const currentDate = new Date();
    const formattedCurrentDate =
      currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const calendarDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    const isCalendarDateToday = calendarDate === formattedCurrentDate;

    const calendarCellEvents =
      props?.events?.filter(
        (event: any) =>
          event?.resource?.eventDate?.getDate() === date.getDate() &&
          event?.resource?.eventDate?.getMonth() === date.getMonth() &&
          event?.resource?.eventDate?.getFullYear() === date.getFullYear()
      ) || [];

    const getCalendarCellEventsTotalDuration = () => {
      const durations: string[] = calendarCellEvents.map((item) => item.resource.totalDuration).filter((item) => item);
      const totalDuration = sumEventsDurations(durations);
      if (isDefined(totalDuration) && totalDuration !== '-') {
        return totalDuration;
      }
      return;
    };

    const sumEventsDurations = (durations: string[]) => {
      let totalHours = 0;
      let totalMinutes = 0;

      if (durations.length === 0) {
        return;
      }

      durations.forEach((timeString, index) => {
        const [hours, minutes] = timeString.match(/\d+/g)!.map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      });
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;

      return `${totalHours}h ${totalMinutes}m`;
    };

    return (
      <div className="d-flex ">
        <div className={isCalendarDateToday ? classes.todayLabel : ''}>{label}</div>
        {isDefined(getCalendarCellEventsTotalDuration()) ? (
          <>
            {/* {calendarCellEvents[0]?.resource?.edited ? (
              <span className={classes.edited}>
                {' '}
                <img src={Edit} alt="" />{' '}
              </span>
            ) : undefined} */}
            <p className={classes.duration}>
              <span className={isCalendarDateToday ? classes.durationToday : ''}>
                (Duration: {getCalendarCellEventsTotalDuration()})
              </span>
            </p>
          </>
        ) : undefined}
      </div>
    );
  };

  return (
    <BigCalendar
      {...props}
      localizer={localizer}
      selectable={true}
      tooltipAccessor="allDay"
      components={{
        toolbar: ToolBar,
        event: EventInfo,
        month: { header: MonthHeader, dateHeader: CustomDateHeader },
      }}
    />
  );
}
