import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import classes from './style/EditEmployee.module.scss';

import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { Employee, RepoType, UserRole } from '../../../types/sharedTypes';
import { Option } from '../../../types/sharedTypes';

import { useTranslation } from '../../../providers/locale-provider';
import SettingLayout from '../../../view/layouts/SettingLayout';
import Loading from '../../../components/base/Loading';
import SelectInput from '../../../components/base/SelectInput';
import { capitalizeFirst } from '../../../core/helpers/utils';
import Toast from '../../../components/base/Toast';
import DatePicker from '../../../components/base/DatePicker';
import InputPassword from '../../../components/base/InputPassword';
import { isValidEmail } from '../../../core/helpers/FormValidation';
import TimeOffModal from './TimeOffModal';
import { calculateLeaves } from '../../../core/helpers/utils';

export interface ITimeOff {
  days: number;
  hours: number;
  minutes: number;
  isPositive: boolean;
}

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);

const EditProject = () => {
  const { t9n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<Option>({ value: '', title: '' });
  const [password, setPassword] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showTimeOffModal, setShowTimeOffModal] = useState<boolean>(false);
  const [isIncrease, setIsIncrese] = useState<boolean>(false);
  const [mainTimeOff, setMainTimeOff] = useState<ITimeOff>({ days: 0, hours: 0, minutes: 0, isPositive: true });
  const [editNumbers, setEditNumbers] = useState<ITimeOff>();
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    selectedDate: false,
  });

  const [employeeDetails, setEmployeeDetails] = useState({
    addPunchTimeLimit: '',
    editAttendanceLimit: '',
    updatePunchCountlimit: '',
    newPunchCountlimit: '',
  });

  const handleEmployeeDetails = (field: string, value: string) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case 'email':
        setEmail(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
  };

  useEffect(() => {
  }, [mainTimeOff])

  useEffect(() => {
  }, [editNumbers])

  const handleToastClose = () => setShowToast(false);
  const handleSelectedOption = (selectedItem: Option) => setRole(selectedItem);
  const handleSelectedDate = (date: Date) => {
    setSelectedDate(date);
    setErrors({ ...errors, selectedDate: false });
  };
  const handleTimeOffModalClose = () => setShowTimeOffModal(false);

  const handleTimeOff = (editedTimeOff: ITimeOff) => {
    setEditNumbers(editedTimeOff);
    setMainTimeOff(editedTimeOff);
    handleTimeOffModalClose();
  };

  const getEmployee = () => {
    setLoading(true);

    EmployeesRepository()
      .getEmployee(id)
      .then((res: AxiosResponse<any>) => {
        const employee: Employee = res.data;
        setFirstName(employee.name);
        setLastName(employee.last_name);
        setEmail(employee.email || '');
        setSelectedDate(employee.employment_time ? new Date(+employee.employment_time * 1000) : undefined);
        setRole({ value: employee.role.title, title: capitalizeFirst(employee.role.title) });
        let leaveTime = calculateLeaves(Math.abs(employee.annual_leaves), 24);
        if(employee.annual_leaves < 0){
          leaveTime.isPositive = false;
        }
        setMainTimeOff(leaveTime);
        setEmployeeDetails({
          addPunchTimeLimit: res.data.new_punch_dayslimit,
          editAttendanceLimit: res.data.update_punch_dayslimit,
          updatePunchCountlimit: res.data.update_punch_countlimit,
          newPunchCountlimit: res.data.new_punch_countlimit,
        });

        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const validateForm = () => {
    const newErrors = {
      email: !isValidEmail(email) || email.length === 0,
      firstName: firstName.length === 0,
      lastName: lastName.length === 0,
      selectedDate: selectedDate ? false : true,
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const updateEmployee = () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    setLoading(true);
    let leavesSec = editNumbers
      ? (editNumbers.days * 86400 + editNumbers.hours * 3600 + editNumbers.minutes * 60) *
        (editNumbers.isPositive ? 1 : -1)
      : undefined;
    const body = {
      name: firstName,
      last_name: lastName,
      role: role.value as UserRole,
      email: email === '' ? null : email,
      password,
      employment_time: selectedDate ? Math.floor(selectedDate.getTime() / 1000) : null,
      update_punch_countlimit: employeeDetails.updatePunchCountlimit,
      new_punch_countlimit: employeeDetails.newPunchCountlimit,
      new_punch_dayslimit: employeeDetails.addPunchTimeLimit,
      update_punch_dayslimit: employeeDetails.editAttendanceLimit,
      annual_leaves: leavesSec,
    };

    EmployeesRepository()
      .updateEmployee(body, id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        getEmployee();
        setShowToast(true);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <SettingLayout>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.edit_employee_success_msg} onClose={handleToastClose} />}
      {showTimeOffModal && (
        <TimeOffModal
          timeOff={mainTimeOff}
          isIncrease={isIncrease}
          exitAction={handleTimeOffModalClose}
          handleSave={handleTimeOff}
        />
      )}
      <div className={classes.container}>
        <div className={classes.title}>{t9n.employee_settings_text}</div>
        <div className={classes.form}>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.first_name_text}</label>
              <input
                className={classes.formInput}
                type="text"
                placeholder={t9n.first_name_text}
                value={firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
              />
              {errors.firstName && <div className={classes.errorText}>{t9n.employee_Fname_invalid_error_text}</div>}
            </div>
            <div className={classes.inputContainer}>
              <label>{t9n.last_name_text}</label>
              <input
                className={classes.formInput}
                type="text"
                placeholder={t9n.last_name_text}
                value={lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
              />
              {errors.lastName && <div className={classes.errorText}>{t9n.employee_Lname_invalid_error_text}</div>}
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.start_date_text}</label>
              <DatePicker
                handleSelectedDate={handleSelectedDate}
                selectedDate={selectedDate}
                isInvalid={errors.selectedDate}
                inValidmsg={t9n.employee_startDate_invalid_error_text}
              />
            </div>
            <div className={classes.inputContainer}>
              <SelectInput
                label={t9n.role_text}
                placeHolder={t9n.role_select_placeholder}
                options={[
                  { value: UserRole.MANAGER, title: t9n.manager_text },
                  { value: UserRole.REGULAR, title: t9n.regular_text },
                ]}
                defaultValue={role}
                handleselectedOption={handleSelectedOption}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.email_text}</label>
              <input
                className={classes.formInput}
                type="text"
                placeholder="email@gamil.com"
                value={email}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
              {errors.email && <div className={classes.errorText}>{t9n.invalid_email_error}</div>}
            </div>
            <div className={classes.inputContainer}>
              <InputPassword
                name="Password"
                placeholder={'********'}
                title={t9n.password_text}
                handleChange={(e) => setPassword(e.target.value)}
                autoComplete={'new-password'}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.edit_date_limit}</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder={'e.g. 20'}
                  value={employeeDetails.editAttendanceLimit}
                  onChange={(e) => handleEmployeeDetails('editAttendanceLimit', e.target.value)}
                  autoComplete="new-password"
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    width: '255px',
                  }}
                />
                <div
                  style={{
                    border: '1px solid #ced4da',
                    padding: '11.2px 8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span>Day</span>
                </div>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <label>{t9n.manual_addition_date_limit}</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder={'e.g. 20'}
                  value={employeeDetails.addPunchTimeLimit}
                  onChange={(e) => handleEmployeeDetails('addPunchTimeLimit', e.target.value)}
                  autoComplete="new-password"
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    width: '255px',
                  }}
                />
                <div
                  style={{
                    border: '1px solid #ced4da',
                    padding: '11.2px 8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span>Day</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.maximum_edits_allowed}</label>
              <input
                className={classes.formInput}
                type="text"
                placeholder={'e.g. 20'}
                value={employeeDetails.updatePunchCountlimit}
                onChange={(e) => handleEmployeeDetails('updatePunchCountlimit', e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={classes.inputContainer}>
              <label>{t9n.maximum_manual_additions_allowed}</label>
              <input
                className={classes.formInput}
                type="text"
                placeholder={'e.g. 20'}
                value={employeeDetails.newPunchCountlimit}
                onChange={(e) => handleEmployeeDetails('newPunchCountlimit', e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.inputContainer}>
              <label>{t9n.paid_time_off_per_year}</label>
              <div className={classes.timeOff}>
                <div>
                  {mainTimeOff.isPositive ? '' : '-'}
                  {mainTimeOff.days}d {mainTimeOff.hours}h {mainTimeOff.minutes}m
                </div>
                <div className={classes.timeOffBtns}>
                  <button
                    className={classes.timeOffBtn}
                    onClick={() => {
                      setShowTimeOffModal(true);
                      setIsIncrese(true);
                    }}>
                    Increase
                  </button>
                  <button
                    className={classes.timeOffBtn}
                    onClick={() => {
                      setShowTimeOffModal(true);
                      setIsIncrese(false);
                    }}>
                    Decrease
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <label>{t9n.Off_shift_treshold_limit}</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className={classes.formInput}
                  type="text"
                  placeholder={'e.g. 20'}
                  // value={employeeDetails.editAttendanceLimit}
                  // onChange={(e) => handleEmployeeDetails('editAttendanceLimit', e.target.value)}
                  autoComplete="new-password"
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    width: '255px',
                  }}
                />
                <div
                  style={{
                    border: '1px solid #ced4da',
                    padding: '11.2px 8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span>Hour</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.action}>
          <button className={classes.actionbtn} onClick={updateEmployee}>
            {t9n.save_btn_text}
          </button>
        </div>
      </div>
    </SettingLayout>
  );
};
export default EditProject;
