import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../../store/error-slice';
import edit from '../../../../assets/image/Icons/edit.svg';
import classes from './style/EditableField.module.scss';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { RepoType } from '../../../../types/sharedTypes';
import Loading from '../../../../components/base/Loading';

interface EditableFieldProps {
  projectId: number;
  value: string;
  isName: boolean;
}

enum FieldType{
  Name= 'name',
  Description= 'description'
}

const projectRepository = () => RepoFactory.get(RepoType.Project);

const EditableField = (props: EditableFieldProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(props.value);

  const dispatch = useDispatch();

  const handleEdit = () => setIsEditing(true);
  const fieldName = props.isName ? FieldType.Name : FieldType.Description;

  const editField = () => {
    if (fieldValue.length || fieldName === FieldType.Description) {
      setLoading(true);

      const body: { name?: string; description?: string } = {};

      if (props.isName) {
        body.name = fieldValue;
      } else {
        body.description = fieldValue;
      }

      projectRepository()
        .editProject(body, props.projectId)
        .then((response: AxiosResponse) => {
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          dispatch(errorActions.setError({ errorMessage: error.message }));
        });
    } else {
      setLoading(false);
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: 'This field cannot be empety!',
          statusCode: 0,
        })
      );
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  const handleBlur = () => {
    checkInfo();
    editField();
  };

  // Event handler for key down (checking for 'Enter' key)
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkInfo();
      editField();
    }
  };

  // Function to check information before finalizing the edit
  const checkInfo = () => {
    if (fieldValue.length || fieldName === FieldType.Description) {
      setIsEditing(false);
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          message: `Enter Your Project ${fieldName}!`,
          statusCode: 0,
        })
      );
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div>
        {isEditing ? (
          <input
            className={`${classes.editInput} ${props.isName ? classes.projectName : classes.projectDescription}`}
            type="text"
            value={fieldValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <div onClick={handleEdit}>
            <span className={`${props.isName ? classes.projectName : classes.projectDescription}`}>{fieldValue}</span>
            <img className={classes.editPen} src={edit} alt="Edit icon" />
          </div>
        )}
      </div>
    </>
  );
};

export default EditableField;
