import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import classes from './style/TimeSheet.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import Loading from '../../../components/base/Loading';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { convertDate, timeToSeconds } from '../../../core/helpers/FormatDate';
import { errorActions } from '../../../store/error-slice';
import { IPunch } from '../repository/timeSheetRepository';
import { Optional, PunchType, RepoType } from '../../../types/sharedTypes';
import { UserStatus } from '../../Auth/types/type';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BaseButton from '../../../components/base/BaseButton';
import TextArea from '../../../components/base/TextArea';
import DeleteAttendance from '../components/Modals/DeleteAttendance';
import Input from '../../../components/base/Input';
import DatePicker, { DatePickerPosition, DatePickerSize } from '../../../components/base/DatePicker';
import { useTranslation } from '../../../providers/locale-provider';
import { ActionType } from '../types/types';
import { IAppLocale } from '../../../locales';
import { getTimeDifference, isDefined, isValidDate, secondsToHourMinute } from '../../../core/helpers/utils';
import Info from '../../../components/base/Info';
const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);
const projectRepository = () => RepoFactory.get(RepoType.Project);
const profileRepository = () => RepoFactory.get(RepoType.Profile);

// const today = new Date();

// // Subtract one day (in milliseconds)
// var yesterday = new Date(today);
// yesterday.setDate(today.getDate() - 1);
interface Project {
  isDescriptionRequired: boolean;
  project: {
    id: number;
    name: string;
    description: string;
    edited: boolean;
  };
  details: ProjectDetails;
}
interface ProjectDetails {
  active: boolean;
  attendanceId: number | null;
  description: string;
  duration: {
    hour: number | string;
    minute: number | string;
  };
}
interface Duration {
  id: number;
  name: string;
  duration:
    | {
        hour: number | string;
        minute: number | string;
      }
    | number
    | string;
}
interface AttendanceApi {
  attendanceDur: string;
  break: string;
  checkIn: string;
  checkOut: string;
  day: string;
  id: number;
  projectDuration: Duration[];
  projects: any[];
}

interface Details {
  projectId: number;
  duration: number;
  description?: string;
}

interface Attendance {
  id: Optional<number>;
  punch_in_time: Optional<Dayjs>;
  punch_in_date: Optional<Date>;
  punch_out_time: Optional<Dayjs>;
  punch_out_date: Optional<Date>;
}

const PunchOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const { data } = location.state;
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [userAllProjects, setUserAllProjects] = useState<Project[]>([]);
  const [optionalProject, setOptionalProject] = useState(false);
  const [attendance, setAttendance] = useState<Attendance>({
    id: null,
    punch_in_time: null,
    punch_in_date: null,
    punch_out_time: null,
    punch_out_date: null,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const selectedDate = convertDate('YYYY-MM-DD', data.date.getTime());
    const projectsReq = projectRepository().userProjects();
    const attendanceReq = timeSheetRepository().attendanceDay({ date: selectedDate });
    setLoading(true);
    Promise.all([projectsReq, attendanceReq])
      .then(
        ([
          {
            data: { result: projectsRes },
          },
          {
            data: { result: attendanceRes },
          },
        ]) => {
          populateAttendanceInfo(attendanceRes);
          const allProjects = populateUserProjects(projectsRes);
          const updatedProjects = populateUserAttendanceToProjects(attendanceRes, allProjects);
          setUserAllProjects(updatedProjects);
        }
      )
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProfile = () => {
    setLoading(true);

    profileRepository()
      .getProfile()
      .then((res: AxiosResponse<any>) => {
        const user = res.data.result;
        setLoading(false);
        setOptionalProject(user.optional_punchout_modal);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getAttendanceDuration = () => {
    const { punch_in_time, punch_out_time, punch_in_date, punch_out_date } = attendance;

    if (
      !isDefined(punch_in_time) ||
      !isDefined(punch_out_time) ||
      !isDefined(punch_in_date) ||
      !isDefined(punch_out_date)
    ) {
      return;
    }

    const startDate = combinedDateTime(punch_in_date, punch_in_time);
    const endDate = combinedDateTime(punch_out_date, punch_out_time);

    return getTimeDifference({ start: startDate, end: endDate });
  };

  const combinedDateTime = (date: Date, time: Dayjs) => {
    // this method generate a new date based on the provided date and time
    const year = date?.getFullYear();
    const month = date?.getMonth();
    const day = date?.getDate();

    const hour = time.hour();
    const minute = time.minute();
    const second = time.second();
    const millisecond = time.millisecond();

    return new Date(year, month, day, hour, minute, second, millisecond);
  };

  const handleDatePickerChange = (date: Optional<Date>, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => {
    if (!date) return;
    let hasError = false;
    let punchOut: Date = new Date();
    const now: Date = new Date();
    if (attendance.punch_out_time) {
      punchOut.setTime(attendance.punch_out_time?.valueOf());
      punchOut.setDate(date?.getDate());
      punchOut.setMonth(date?.getMonth());
      punchOut.setFullYear(date?.getFullYear());
    }
    let errorMessage = '';

    if (type === PunchType.PUNCHIN && isDefined(attendance.punch_out_date) && date > attendance.punch_out_date) {
      errorMessage = 'The punch-in date should be earlier than the punch-out.';
    }

    if (type === PunchType.PUNCHOUT && isDefined(attendance.punch_in_date) && punchOut < attendance.punch_in_date) {
      errorMessage = 'The punch-out date needs to be later than the punch-in.';
    }

    if (type === PunchType.PUNCHOUT && punchOut > now) {
      errorMessage = 'The punch-out time cannot be later than the current time.';
      hasError = true;
    }

    if (errorMessage) {
      dispatch(errorActions.setHasError(true));
      dispatch(errorActions.setError({ state: 'error', message: errorMessage }));
    }

    if (hasError) {
      return;
    }

    setAttendance({
      ...attendance,
      [type === PunchType.PUNCHIN ? 'punch_in_date' : 'punch_out_date']: date,
    });
  };

  const handleTimePickerChange = (time: Optional<Dayjs>, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => {
    if (!time) return;
    setAttendance({
      ...attendance,
      [type === PunchType.PUNCHIN ? 'punch_in_time' : 'punch_out_time']: time,
    });
  };

  const goToPreviousPage = () => navigate(-1);
  const findEditedValue = (data: any, projectId: number): boolean => {
    for (const project of data.projects) {
      if (project.projectId === projectId) {
        return project.edited === 1 ? true : false;
      }
    }
    return false;
  };

  const populateUserProjects = ({ projectDescriptions }: { data: any[]; metadata: any; projectDescriptions: any[] }) =>
    projectDescriptions.map(({ project, description }, index) => ({
      project: {
        id: project.id,
        name: project.name,
        description: project?.description,
        edited: false,
      },
      isDescriptionRequired: !!description,
      details: {
        active: false,
        attendanceId: null,
        description: '',
        duration: { hour: '', minute: '' },
      },
    }));

  const populateAttendanceInfo = ({ allAtten }: { allAtten: AttendanceApi[] }) => {
    if (data.state === ActionType.Add) {
      setAttendance({
        ...attendance,
        punch_in_date: new Date(data.date),
        punch_out_date: new Date(data.date),
      });
      return;
    }

    const filteredAttendance = allAtten.filter((item: any) => item.id === data.attendanceId).pop();
    if (!isDefined(filteredAttendance)) {
      return;
    }

    const { id, checkIn, checkOut } = filteredAttendance;

    if (checkOut === 'N/A') {
      setAttendance({
        ...attendance,
        id: id,
        punch_in_date: new Date(+checkIn * 1000),
        punch_out_date: new Date(),
        punch_in_time: dayjs(new Date(+checkIn * 1000)),
        punch_out_time: dayjs(new Date()),
      });
    } else {
      setAttendance({
        ...attendance,
        id: id,
        punch_in_date: new Date(+checkIn * 1000),
        punch_out_date: new Date(+checkOut * 1000),
        punch_in_time: dayjs(new Date(+checkIn * 1000)),
        punch_out_time: dayjs(new Date(+checkOut * 1000)),
      });
    }
  };
  const populateUserAttendanceToProjects = ({ allAtten }: { allAtten: AttendanceApi[] }, allProjects: Project[]) => {
    if (data.state === ActionType.Add) {
      return allProjects;
    }

    const filteredAttendance = allAtten.filter((item: any) => item.id === data.attendanceId).pop();
    if (!isDefined(filteredAttendance)) {
      return allProjects;
    }

    return allProjects.map((userProject) => {
      const match = filteredAttendance.projects
        .filter((attendanceProject) => userProject.project.id === attendanceProject.project.id)
        .pop();
      userProject.details.active = !!match;

      if (isDefined(match)) {
        userProject.details.attendanceId = match.attendanceId;
        userProject.details.description = match.description;
        userProject.project.edited = findEditedValue(filteredAttendance, userProject.project.id);

        const { hours, minutes } = secondsToHourMinute(match.duration);
        userProject.details.duration.hour = hours;
        userProject.details.duration.minute = minutes;
      }

      if (
        userProject.details.description === '' &&
        userProject.details.duration.hour === 0 &&
        userProject.details.duration.minute === 0
      ) {
        userProject.details.active = false;
      }
      return userProject;
    });
  };
  const updateProjectDetailsHandler = (e: React.FormEvent<HTMLInputElement>, property: string) => {
    const value = e.currentTarget.value;
    const id = e.currentTarget.id;

    setUserAllProjects(
      userAllProjects.map((item) => {
        if (item.project.id.toString() === id) {
          switch (property) {
            case 'hour':
            case 'minute':
              item.details.duration[property] = value;
              break;
            case 'description':
              item.details[property] = value;
              break;
            case 'active':
              item.details[property] = !item.details.active;
              break;
            default:
              console.error('Unknown property', property);
          }
        }
        return item;
      })
    );
  };

  const saveChanges = () => {
    let errorMessage: Optional<string> = null;
    const { punch_in_time, punch_out_time, punch_in_date, punch_out_date } = attendance;
    if (
      !isDefined(punch_in_time) ||
      !isDefined(punch_out_time) ||
      !isDefined(punch_in_date) ||
      !isDefined(punch_out_date)
    ) {
      errorMessage = 'Please enter all required data!';
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          state: 'error',
          message: errorMessage,
        })
      );
      return;
    }
    const startDate = combinedDateTime(punch_in_date, punch_in_time);
    const endDate = combinedDateTime(punch_out_date, punch_out_time);
    const startDate_ = new Date(startDate);
    const endDate_ = new Date(endDate);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate_.getTime() - startDate_.getTime();

    if (timeDifference < 0) {
      errorMessage = 'The punch in time is greater than the punch out time!';
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          state: 'error',
          message: errorMessage,
        })
      );
      return;
    }

    const val: IPunch = {
      punchIn: combinedDateTime(punch_in_date, punch_in_time).getTime() / 1000,
      punchOut: combinedDateTime(punch_out_date, punch_out_time).getTime() / 1000,
      detail: [],
    };

    userAllProjects.forEach(({ project, details }) => {
      if (details.active) {
        const calculatedDuration = timeToSeconds(Number(details.duration.hour), Number(details.duration.minute));
        if (calculatedDuration === 0) {
          errorMessage = 'Please enter duration time for each selected projects!';
        }
        val.detail!.push({
          projectId: project.id,
          duration: calculatedDuration,
          description: details.description,
        });
      }
    });
    if (isDefined(errorMessage)) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          state: 'error',
          message: errorMessage,
        })
      );
      return;
    }

    if (data.state === ActionType.Add) {
      setLoading(true);
      timeSheetRepository()
        .addNewPunch(val)
        .then((_: AxiosResponse) => {
          goToPreviousPage();
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
          }
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    setLoading(true);
    timeSheetRepository()
      .updatePunchOut(data.attendanceId, val)
      .then((_: AxiosResponse) => {
        goToPreviousPage();
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <section className={` mb-4 mt-4`}></section>
      <section>
        <TableContainer>
          {data.isEditDayLimit ? (
            <Info text={`You cannot update punches that were made more than ${data.isEditDayLimit - 1} days ago.`} />
          ) : (
            data.isEditCountLimit && (
              <Info
                text={`The number of times attendance records can be updated is limited to ${data.isEditCountLimit} in any ${data.updatePunchLimit}-day period.`}
              />
            )
          )}
          <Table aria-label="collapsible table" className={`${classes.table} mb-3`}>
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={1}>
                  <h5 className={classes.date}>
                    {data.state === ActionType.Add ? t9n.add_text : t9n.edit_text} -{' '}
                    {convertDate('DD MMMM YYYY', data.date.getTime())}
                  </h5>
                </TableCell>
                <TableCell align="right" colSpan={11}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">{t9n.punch_in_date}</TableCell>
                <TableCell align="center">{t9n.punch_in_btn_text}</TableCell>
                <TableCell align="center">{t9n.punch_out_date}</TableCell>
                <TableCell align="center">{t9n.punch_out_btn_text}</TableCell>
                <TableCell align="center">{t9n.duration_text}</TableCell>
                {data.state === ActionType.Edit && <TableCell />}
                {/* <TableCell align="center"></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbodyTable}>
              <Fragment>
                <>
                  <AttendanceInfo attendance={attendance} getDuration={getAttendanceDuration}>
                    {data.state === ActionType.Edit ? (
                      <TableCell className={`borderTable`} align="center">
                        <DeleteAttendance
                          attendanceId={data.attendanceId}
                          disabled={!isDefined(getAttendanceDuration()) || data.isEditLimit}
                          handleDeleteAttendance={() => {
                            goToPreviousPage();
                          }}
                        />
                      </TableCell>
                    ) : null}
                  </AttendanceInfo>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <section className="pt-3 pb-3">
                        <PunchingForm
                          attendance={attendance}
                          t9n={t9n}
                          currentDate={data.date.getTime()}
                          handleDatePickerChange={handleDatePickerChange}
                          handleTimePickerChange={handleTimePickerChange}
                          data={data}
                        />
                        <div>
                          <p className={`${classes.workDuration}`}>
                            {t9n.work_duration_text} : <span>{getAttendanceDuration() ?? '--'}</span>
                          </p>
                        </div>
                        <div className={`d-flex flex-wrap ${classes.rowTable}`}>
                          {userAllProjects.map((project, index) => (
                            <Fragment key={index}>
                              <ProjectForm
                                project={project}
                                t9n={t9n}
                                activeProject={(e) => updateProjectDetailsHandler(e, 'active')}
                                hoursHandler={(e) => updateProjectDetailsHandler(e, 'hour')}
                                minutesHandler={(e) => updateProjectDetailsHandler(e, 'minute')}
                                descriptionHandler={(e) => updateProjectDetailsHandler(e, 'description')}
                                allProjects={userAllProjects}
                                optionalProject={optionalProject}
                                data={data}
                              />
                            </Fragment>
                          ))}
                        </div>
                        <div className="d-flex justify-content-center">
                          <BaseButton
                            title={t9n.save_changes_btn_text}
                            onClickHandler={saveChanges}
                            disable={!isValidDate(attendance.punch_out_date) || data.isEditDayLimit}
                          />
                        </div>
                      </section>
                    </TableCell>
                  </TableRow>
                </>
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </DefaultLayout>
  );
};
export default PunchOut;

interface IAttendanceInfo {
  attendance: Attendance;
  children: Optional<JSX.Element>;
  getDuration: () => string | undefined;
}

export const AttendanceInfo = ({ attendance, children, getDuration }: IAttendanceInfo) => {
  const formatDate = (date: Optional<Date>) => {
    if (!isDefined(date)) {
      return;
    }
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const punchOutDate =
    isValidDate(attendance.punch_out_date) &&
    attendance.punch_out_date &&
    convertDate('MMM D, YYYY', +attendance.punch_out_date);
  const punchOutTime = isValidDate(attendance.punch_out_time) && attendance.punch_out_time?.format('HH:mm');

  return (
    <TableRow>
      <TableCell className={`borderTable`} align="center">
        {attendance.punch_in_date ? convertDate('MMM D, YYYY', +attendance.punch_in_date) : '--'}
      </TableCell>
      <TableCell className={`borderTable`} align="center">
        {attendance.punch_in_time?.format('HH:mm') ?? '--'}
      </TableCell>
      <TableCell className={`borderTable`} align="center">
        {punchOutDate || '--'}
      </TableCell>
      <TableCell className={`borderTable`} align="center">
        {punchOutTime || '--'}
      </TableCell>
      <TableCell className={`borderTable`} align="center">
        {getDuration() ?? '--'}
      </TableCell>
      {children}
    </TableRow>
  );
};

interface IProject {
  project: Project;
  t9n: IAppLocale;
  activeProject: (e: ChangeEvent<HTMLInputElement>) => void;
  hoursHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  minutesHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  descriptionHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  allProjects: Project[];
  optionalProject: boolean;
  data: any;
}

export const ProjectForm = ({
  project: {
    project: { id, name, edited },
    details: { active, description, duration },
    isDescriptionRequired,
  },
  t9n,
  activeProject,
  hoursHandler,
  minutesHandler,
  descriptionHandler,
  allProjects,
  optionalProject,
  data,
}: IProject) => {
  if (allProjects.length === 1 && !optionalProject && !isDescriptionRequired) {
    return null;
  }
  return (
    <div className={`w-50 p-2`}>
      <div className={`${classes.formSection}`}>
        <div className="w-100 d-flex align-items-center mb-2">
          <label className="container-checkbox ">
            <input
              type="checkbox"
              id={id.toString()}
              name={id.toString()}
              checked={active || false}
              value={id}
              onChange={activeProject}
              disabled={data.isEditLimit}
            />
            <span className="checkmark"></span>
          </label>
          <span className={`mx-1 ${classes.labelProject}`}>{name}</span>
        </div>
        <div className="w-100 mt-2">
          <label className={classes.labelInput} htmlFor="">
            {t9n.duration_text}
          </label>
        </div>
        <div className="mb-3 d-flex flex-wrap ">
          <div className={`w-50 pe-2 pt-2`}>
            <Input
              disable={!active || data.isEditLimit}
              id={id.toString()}
              type="number"
              name="hours"
              min={0}
              max={100000}
              value={duration.hour}
              handleChange={hoursHandler}
              centerText={true}
              placeholder="h"
              autoComplete={'off'}
            />
          </div>
          <div className={`w-50 px-2 pt-2`}>
            <Input
              disable={!active || data.isEditLimit}
              id={id.toString()}
              type="number"
              name="minutes"
              min={0}
              max={59}
              centerText={true}
              value={duration.minute}
              handleChange={minutesHandler}
              placeholder="m"
              autoComplete={'off'}
            />
          </div>
        </div>
        <TextArea
          edited={edited}
          disable={!active || data.isEditLimit}
          id={id.toString()}
          title={t9n.leave_description_text}
          name="description"
          required={isDescriptionRequired}
          rows={3}
          value={description}
          handleChange={descriptionHandler}
        />
      </div>
    </div>
  );
};

interface IPunchingForm {
  attendance: Attendance;
  t9n: IAppLocale;
  currentDate: number;
  handleDatePickerChange: (date: Date, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => void;
  handleTimePickerChange: (newValue: Dayjs | null, type: PunchType.PUNCHIN | PunchType.PUNCHOUT) => void;
  data: any;
}

export const PunchingForm = ({
  t9n,
  attendance,
  handleDatePickerChange,
  handleTimePickerChange,
  currentDate,
  data,
}: IPunchingForm) => {
  const [punchInVisibility, setPunchInVisibility] = useState(false);
  const [punchOutVisibility, setPunchOutVisibility] = useState(false);
  // const [disabledFuture, setDisabledFuture] = useState(false);

  const toggleDatePicker = (picker: 'in' | 'out') => {
    if (picker === 'in') {
      setPunchInVisibility(true);
      setPunchOutVisibility(false);
    } else {
      setPunchOutVisibility(true);
      setPunchInVisibility(false);
    }
  };

  return (
    <div className={`d-flex justify-content-between align-items-end gap-4 mb-3 position-relative`}>
      <div className={`d-flex flex-column`}>
        <span>{t9n.punch_in_btn_text}</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            slotProps={{
              actionBar: {
                actions: ['cancel', 'accept'],
              },
              textField: {
                inputProps: {
                  readOnly: true, // Prevent user from typing in the input field
                },
              },
            }}
            sx={{
              width: 300,
            }}
            name={UserStatus.In}
            ampm={false}
            timeSteps={{
              hours: 1,
              minutes: 1,
            }}
            disableFuture={
              convertDate('YYYY MM DD', Number(attendance.punch_in_date)) ===
                convertDate('YYYY MM DD', new Date().getTime()) ||
              (convertDate('YYYY MM DD', currentDate) === convertDate('YYYY MM DD', new Date().getTime()) &&
                convertDate('YYYY MM DD', Number(attendance.punch_in_date)) ===
                  convertDate('YYYY MM DD', new Date().getTime()))
                ? true
                : false
            }
            disabled={data.isEditLimit}
            closeOnSelect={false}
            selectedSections="all"
            value={attendance.punch_in_time}
            onAccept={(value) => {
              if (!value) {
                return;
              }
              handleTimePickerChange(value, PunchType.PUNCHIN);
            }}
          />
        </LocalizationProvider>
      </div>
      <DatePicker
        handleSelectedDate={(date) => handleDatePickerChange(date, PunchType.PUNCHIN)}
        selectedDate={attendance.punch_in_date as Date}
        defaultDate={attendance.punch_out_date as Date}
        datePickerPosition={DatePickerPosition.RIGHT_Center}
        datePickerSize={DatePickerSize.MEDIUM}
        disabled={new Date()}
        isVisible={punchInVisibility}
        onClick={() => toggleDatePicker('in')}
        disableAll={data.isEditLimit}
      />
      <div className={`d-flex flex-column`}>
        <span>{t9n.punch_out_btn_text}</span>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            slotProps={{
              actionBar: {
                actions: ['cancel', 'accept'],
              },
              textField: {
                inputProps: {
                  readOnly: true, // Prevent user from typing in the input field
                },
              },
            }}
            sx={{
              width: 300,
            }}
            name={UserStatus.Out}
            ampm={false}
            timeSteps={{
              hours: 1,
              minutes: 1,
            }}
            closeOnSelect={false}
            disableFuture={
              convertDate('YYYY MM DD', Number(attendance.punch_out_date)) ===
                convertDate('YYYY MM DD', new Date().getTime()) ||
              (convertDate('YYYY MM DD', currentDate) === convertDate('YYYY MM DD', new Date().getTime()) &&
                convertDate('YYYY MM DD', Number(attendance.punch_out_date)) ===
                  convertDate('YYYY MM DD', new Date().getTime()))
                ? true
                : false
            }
            disabled={data.isEditLimit}
            selectedSections="all"
            value={attendance.punch_out_time}
            onAccept={(value) => {
              if (!value) {
                return;
              }
              handleTimePickerChange(value, PunchType.PUNCHOUT);
            }}
          />
        </LocalizationProvider>
      </div>
      <DatePicker
        handleSelectedDate={(date) => handleDatePickerChange(date, PunchType.PUNCHOUT)}
        selectedDate={attendance.punch_out_date as Date}
        defaultDate={attendance.punch_out_date as Date}
        datePickerPosition={DatePickerPosition.LEFT_Center}
        datePickerSize={DatePickerSize.MEDIUM}
        disabled={new Date()}
        isVisible={punchOutVisibility}
        onClick={() => toggleDatePicker('out')}
        disableAll={data.isEditLimit}
      />
    </div>
  );
};
