import DefaultLayout from '../../../view/layouts/DefaultLayout';
import { useEffect, useState } from 'react';
import TabBar from '../../../components/base/TabBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import MonthlyReport from '../components/MonthlyReport';
import BaseButton from '../../../components/base/BaseButton';
import exportIcon from '../../../assets/image/Icons/export.png';
import Loading from '../../../components/base/Loading';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { utils, writeFile } from 'xlsx-color';
import { convertDate, convertToTime, getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { RepoType } from '../../../types/sharedTypes';
import { useTranslation } from '../../../providers/locale-provider';
import { capitalizeWords } from '../../../core/helpers/utils';
import { useParams, useNavigate } from 'react-router-dom';
import ManualReport from '../components/ManualReport';
import { DateRange } from 'react-day-picker';
import { CustomOption } from '../components/MonthlyReport';
import { META_Data, IShiftType, TABS, Data } from '../types/index';
import {
  getBreakTime,
  getShiftTime,
  getTotalTimeOffString,
  checkDurationSplit,
  handleDate,
  getDateValue,
  getTimeValue,
  getDurationMoreThan10Hours
} from '../utils/reportUtils';
import { advancedRecords, manualColumnWidths } from '../utils/advancedReportUtils';
import { monthlyColumnWidths } from '../utils/monthlyReportUtils';

export interface Option {
  value: string;
  label: string;
}

const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);

const DEFAULT_TAB = TABS.MONTHLY_REPORT;

const Report = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TABS>(pageType);
  const [users, setUsers] = useState<CustomOption[]>([]);
  const [advanceuser, setAdvanceUser] = useState<Option[]>([]);
  const [projectId, setProjectId] = useState<string | undefined>('');
  const [date, setDate] = useState<any>(null);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [reportType, setReportType] = useState<number>(0);
  const [bottomExportBtn, setBottomExportBtn] = useState<boolean>(false);

  const tabSelectHandler = (tabValue: string) => {
    // setSelectedTab(getEnumByValue(TABS, tabValue) ?? DEFAULT_TAB);
    navigate(`/reports/${tabValue}/1`);
  };
  useEffect(() => {
    if (pageType === 'monthly_report') {
      setBottomExportBtn(true);
    } else {
      setBottomExportBtn(false);
    }
    setSelectedTab(pageType);
  }, [pageType]);

  const usersSelected = (
    reportType: number,
    user: CustomOption[],
    date: Date | any,
    projectId?: string,
    dateRange?: DateRange | any
  ) => {
    setUsers(user);
    setReportType(reportType);
    if (reportType) {
      setProjectId(projectId);
      setDateRange(dateRange);
    } else {
      setDate(date);
    }
  };

  const advanceUsersSelected = (
    reportType: number,
    user: Option[],
    date: Date | any,
    projectId?: string,
    dateRange?: DateRange | any
  ) => {
    setAdvanceUser(user);
    setReportType(reportType);
    if (reportType) {
      setProjectId(projectId);
      setDateRange(dateRange);
    } else {
      setDate(date);
    }
  };

  const selectedDate = handleDate(reportType, dateRange, date);

  const isFirstDayOfMonth = (timestamp: number): boolean => {
    const date = new Date(timestamp * 1000);
    return date.getDate() === 1;
  };

  const getReport = (employeeId: number | string, employeeName: string, index: number) => {
    setLoading(true);

    let params = {
      userId: employeeId,
      from: selectedDate.from,
      to: selectedDate.to,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };
    timeSheetRepository()
      .getReport(params)
      .then((result: AxiosResponse<any>) => {
        setLoading(false);

        const data = result.data.result.allAtten;
        const userData = result.data.result.user;
        const metaData: META_Data[] = result.data.result.metadata;
        const times = result.data.result;
        const remainingLeaves = times.remainingLeaves;
        const dailyWorkingHour = times.daily_working_hour;
        const approvedLeaves = times.approvedLeaves;

        let dataExcel: any[] = [];
        const isFullname = userData.name && userData.last_name;

        dataExcel.push(
          {
            Name: userData.name + (isFullname ? ' ' : '') + (userData.last_name ? userData.last_name : ''),
            'Start Date': selectedDate.from,
            'End Date': selectedDate.to,
            'Mandatory Working Hours Per Month': times?.mandatory_working_hours_month,
            'Total Working Time': times.formattedTotalAttendance,
            'Total Count of Durations Exceeding 10 Hours': times.allMorethan10HourAttendance,
            'Overtime Work': times.overTime,
            'Work Deduction': times.underTime,
            'Total Number of Working Days': times.allAttendanceDays
              ? times.allAttendanceDays + (times.allAttendanceDays > 1 ? ' Days ' : ' Day ')
              : times.allAttendanceDays,
            'Total More Than 5 Hour Work': times.allMorethan5HourAttendance
              ? times.allMorethan5HourAttendance + (times.allMorethan5HourAttendance > 1 ? ' Days ' : ' Day ')
              : times.allMorethan5HourAttendance,

            'Total Remaining Leaves': getTotalTimeOffString(remainingLeaves, dailyWorkingHour),
            'Total On Shift': times.formattedTotalOnShift,
            'Total Off Shift': times.formattedTotalOffShift,
            'Total Monthly Time Off': getTotalTimeOffString(approvedLeaves, dailyWorkingHour),
          },
          {},
          {},
          {
            Name: 'Start Date',
            'Start Date': 'Start Time',
            'End Date': 'End Date',
            'Mandatory Working Hours Per Month': 'End Time',
            'Total Working Time': 'Working time',
            'Total Count of Durations Exceeding 10 Hours': 'Count of Durations Exceeding 10 Hours',
            'Overtime Work': 'Time Off Duration',
            'Work Deduction': 'Holiday',
            'Total Number of Working Days': 'Weekend',
            'Total More Than 5 Hour Work': 'Edited',
            'Total Remaining Leaves': 'Multi-Day Attendance',
            'Total On Shift': 'On Shift',
            'Total Off Shift': 'Off Shift',
          }
        );

        for (let j = 0; j < data.length; j++) {
          const isFirstAttendanceOfMonth = isFirstDayOfMonth(data[j].checkIn);
          const firstAttendanceOfMonthDurationSplit = isFirstAttendanceOfMonth && data[j].durationSplit ? '** ' : '';
          const lastAttendanceOfMonthDurationSplit = !isFirstAttendanceOfMonth && data[j].durationSplit ? '** ' : '';
          const isAttendance = data[j].isAttendance;
          const isFirstAttendanceOfDay = j > 0 && data[j].day === data[j - 1].day;
          dataExcel.push({
            Name: firstAttendanceOfMonthDurationSplit + getDateValue(data[j].checkIn),
            'Start Date': !isAttendance ? '-' : firstAttendanceOfMonthDurationSplit + getTimeValue(data[j].checkIn),
            'End Date': lastAttendanceOfMonthDurationSplit + getDateValue(data[j].checkOut),
            'Mandatory Working Hours Per Month': !isAttendance
              ? '-'
              : lastAttendanceOfMonthDurationSplit + getTimeValue(data[j].checkOut),
            'Total Working Time': !isAttendance ? '-' : data[j].attendanceDur,
            'Total Count of Durations Exceeding 10 Hours': isFirstAttendanceOfDay ? '' : getDurationMoreThan10Hours(data[j].day, metaData) ? '*' : '',
            'Overtime Work':
              data[j].checkOut === 'N/A' || isFirstAttendanceOfDay
                ? ''
                : getBreakTime(data[j].day, metaData)?.leaveTime,
            'Work Deduction': data[j].isHoliday ? '*' : '',
            'Total Number of Working Days': data[j].isOffDay ? '*' : '',
            'Total More Than 5 Hour Work': data[j].edited ? '*' : '',
            'Total Remaining Leaves': data[j].longEvent ? (data[j].durationSplit ? 'Duration Split' : '*') : '',
            'Total On Shift': isFirstAttendanceOfDay ? '' : getShiftTime(data[j].day, metaData, IShiftType.ON),
            'Total Off Shift': isFirstAttendanceOfDay ? '' : getShiftTime(data[j].day, metaData, IShiftType.OFF),
          });
        }

        if (checkDurationSplit(times)) {
          dataExcel.push(
            {},
            {},
            {
              Name: '** Duration Split occurs when the start and/or end of an attendance is outside the selected range, and only the portion within the selected range is considered in this report.',
            }
          );
        }

        // Convert the data to a worksheet
        const ws = utils.json_to_sheet(dataExcel);
        // Get the range of the sheet
        Object.keys(ws).forEach((cell) => {
          if (cell[0] === '!') return; // Skip special keys
          ws[cell].s = {
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
          };
        });
        for (let C = 0; C < 13; C++) {
          const cellAddress = utils.encode_cell({ r: 4, c: C });
          if (!ws[cellAddress]) continue;
          ws[cellAddress].s = {
            font: { bold: true },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            fill: {
              patternType: 'solid',
              fgColor: { rgb: 'B8CCE4' },
            },
          };
        }

        ws['!cols'] = monthlyColumnWidths;

        const range = utils.decode_range(ws['!ref'] ?? '');

        // Style the header row
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = utils.encode_cell({ r: 0, c: C });
          if (!ws[cellAddress]) continue;
          ws[cellAddress].s = {
            font: { bold: true },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            fill: {
              patternType: 'solid',
              fgColor: { rgb: 'B8CCE4' },
            },
          };
        }

        // Create a new workbook and append the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');

        // Write the workbook to a file
        writeFile(
          wb,
          capitalizeWords(userData.name + (isFullname ? ' ' : '') + (userData.last_name ? userData.last_name : '')) +
            '.xlsx'
        );
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
          setLoading(false);
        }
      });
  };

  const manualReport = (userIds: string, date: DateRange | any, projectId?: string) => {
    const params = {
      userIds: userIds,
      from: convertDate('YYYY-MM-DD', date.from),
      to: convertDate('YYYY-MM-DD', date.to),
      projectId: projectId ?? null,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    timeSheetRepository()
      .manualReport(params)
      .then((result: AxiosResponse<any>) => {
        setLoading(false);
        const data: any[] = result.data.result;
        data.forEach((data) => {
          const allAttendances = data.allAtten;
          const user = data.user;
          const project = data?.project;
          const metaData: META_Data[] = data.metadata;
          const times = data;
          const remainingLeaves = times.remainingLeaves;
          const dailyWorkingHour = data.daily_working_hour;
          const approvedLeaves = data.approvedLeaves;

          let dataExcel: any[] = [];
          const isFullname = user.name && user.last_name;

          if (projectId) {
            dataExcel.push(
              {
                Name: user?.name + (isFullname ? ' ' : '') + (user.last_name ? user.last_name : ''),
                'Project name': project ? project.name : '',
                'Start Date': selectedDate.from,
                'End Date': selectedDate.to,
                'Total Working Time On Project': times.projectDuration[0].formattedDuration,
                'Total Number of Working Days': times.allAttendanceDays
                  ? times.allAttendanceDays + (times.allAttendanceDays > 1 ? ' Days ' : ' Day ')
                  : times.allAttendanceDays,
                '': '',
                ' ': '',
                '  ': '',
              },
              {},
              {},
              {
                Name: 'Start Date',
                'Project name': 'End Date',
                'Start Date': 'Time Off Duration',
                'End Date': 'Holiday',
                'Total Working Time On Project': 'Weekend',
                'Total Number of Working Days': 'Multi-Day Attendance',
                '': 'Total Project Duration',
                ' ': 'Project Duration',
                '  ': 'Description',
              }
            );
          } else {
            dataExcel.push(
              {
                Name: user?.name + (isFullname ? ' ' : '') + (user.last_name ? user.last_name : ''),
                'Start Date': selectedDate.from,
                'End Date': selectedDate.to,
                'Mandatory Working Hours Per Month': times?.mandatory_working_hours_month,
                'Total Working Time': times.formattedTotalAttendance,
                'Total Count of Durations Exceeding 10 Hours': times.allMorethan10HourAttendance,
                'Overtime Work': times.overTime,
                'Work Deduction': times.underTime,
                'Total Number of Working Days': times.allAttendanceDays
                  ? times.allAttendanceDays + (times.allAttendanceDays > 1 ? ' Days ' : ' Day ')
                  : times.allAttendanceDays,
                'Total More Than 5 Hour Work': times.allMorethan5HourAttendance
                  ? times.allMorethan5HourAttendance + (times.allMorethan5HourAttendance > 1 ? ' Days ' : ' Day ')
                  : times.allMorethan5HourAttendance,
                'Total Remaining Time Off': getTotalTimeOffString(remainingLeaves, dailyWorkingHour),
                'Total On Shift': times.formattedTotalOnShift,
                'Total Off Shift': times.formattedTotalOffShift,
                'Total Monthly Time Off': getTotalTimeOffString(approvedLeaves, dailyWorkingHour),
              },
              {},
              {},
              {
                Name: 'Start Date',
                'Start Date': 'Start Time',
                'End Date': 'End Date',
                'Mandatory Working Hours Per Month': 'End Time',
                'Total Working Time': 'Working time',
                'Total Count of Durations Exceeding 10 Hours': 'Count of Durations Exceeding 10 Hours',
                'Overtime Work': 'Time Off Duration',
                'Work Deduction': 'Holiday',
                'Total Number of Working Days': 'Weekend',
                'Total More Than 5 Hour Work': 'Edited',
                'Total Remaining Time Off': 'Multi-Day Attendance',
                'Total On Shift': 'On Shift',
                'Total Off Shift': 'Off Shift',
              }
            );
          }

          const strategyMap: Record<
            string,
            (
              attendance: any,
              metaData: META_Data[],
              isFirstAttendance: boolean,
              projectId?: string,
              isFirstAttendanceOfDay?: boolean
            ) => any
          > = {
            regularRecords: advancedRecords,
          };

          const processAttendanceRecord = (
            attendance: any,
            metaData: META_Data[],
            isFirstAttendance: boolean,
            projectId?: string,
            previousAttendance?: any
          ) => {
            const isFirstAttendanceOfDay = !previousAttendance || attendance.day !== previousAttendance.day;

            return strategyMap.regularRecords(
              attendance,
              metaData,
              isFirstAttendance,
              projectId,
              isFirstAttendanceOfDay
            );
          };

          const processAllAttendances = (
            allAttendances: any[],
            metaData: META_Data[],
            dataExcel: any[],
            projectId?: string
          ) => {
            allAttendances.forEach((attendance, index) => {
              const previousAttendance = index > 0 ? allAttendances[index - 1] : null;
              const isFirstAttendanceOfMonth = isFirstDayOfMonth(attendance.checkIn);
              const record = processAttendanceRecord(
                attendance,
                metaData,
                isFirstAttendanceOfMonth,
                projectId,
                previousAttendance
              );
              dataExcel.push(record);
            });
          };

          processAllAttendances(allAttendances, metaData, dataExcel, projectId);

          if (checkDurationSplit(data)) {
            dataExcel.push(
              {},
              {},
              {
                Name: '** Duration Split occurs when the start and/or end of an attendance is outside the selected range, and only the portion within the selected range is considered in this report.',
              }
            );
          }

          const ws = utils.json_to_sheet(dataExcel);
          // Get the range of the sheet
          Object.keys(ws).forEach((cell) => {
            if (cell[0] === '!') return; // Skip special keys
            ws[cell].s = {
              alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
            };
          });
          for (let R = 0; R < 13; R++) {
            const cellAddress = utils.encode_cell({ r: 4, c: R });
            if (!ws[cellAddress]) continue;
            ws[cellAddress].s = {
              font: { bold: true },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'B8CCE4' },
              },
            };
          }

          ws['!cols'] = manualColumnWidths;

          const range = utils.decode_range(ws['!ref'] ?? '');

          // Style the header row
          const maxRow = projectId ? 5 : range.e.c;
          for (let C = range.s.c; C <= maxRow; ++C) {
            const cellAddress = utils.encode_cell({ r: 0, c: C });
            if (!ws[cellAddress]) continue;
            ws[cellAddress].s = {
              font: { bold: true },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'center' },
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'B8CCE4' },
              },
            };
          }

          const wb = utils.book_new();
          utils.book_append_sheet(wb, ws, 'Data');
          writeFile(
            wb,
            capitalizeWords(user?.name + (isFullname ? ' ' : '') + (user.last_name ? user.last_name : '')) + '.xlsx'
          );
        });
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
          setLoading(false);
        }
      });
  };
  const handleDownloadCSV = () => {
    if (users.length || advanceuser.length) {
      setLoading(true);
      if (reportType) {
        let usersString: string = advanceuser.map((user: Option) => user.value).join(', ');
        manualReport(usersString, dateRange, projectId);
      } else {
        for (let i = 0; i < users.length; i++) {
          getReport(users[i].value, users[i].name, i);
        }
      }
    }
  };

  const TABS_MAP: Record<TABS, JSX.Element> = {
    [TABS.MONTHLY_REPORT]: <MonthlyReport info={usersSelected} />,
    // [TABS.PROJECTS_REPORT]: <div>Projects Report</div>, //TODO
    [TABS.MANUAL_REPORT]: <ManualReport info={advanceUsersSelected} />, //TODO
  };

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <section className="mb-4 mt-4 position-relative">
        <div className={`w-100 mb-3 d-flex justify-content-end`}>
          {!bottomExportBtn && (
            <div className={`d-flex justify-content-end col-3`}>
              <BaseButton
                title={t9n.export_btn_text}
                hasLeftIcon={true}
                icon={<img src={exportIcon} alt="export" />}
                onClickHandler={handleDownloadCSV}
              />
            </div>
          )}
        </div>

        <div className={`mb-3`}>
          <TabBar
            tabs={[
              { name: t9n.monthly_report_text, value: TABS.MONTHLY_REPORT },
              { name: t9n.manual_report_text, value: TABS.MANUAL_REPORT },
            ]}
            onClickHandler={tabSelectHandler}
            selectedValue={selectedTab}
          />
        </div>
        {bottomExportBtn && (
          <div className={`d-flex justify-content-end col-3 position-absolute end-0`}>
            <BaseButton
              title={t9n.export_btn_text}
              hasLeftIcon={true}
              icon={<img src={exportIcon} alt="export" />}
              onClickHandler={handleDownloadCSV}
            />
          </div>
        )}

        {TABS_MAP[selectedTab]}
      </section>
    </DefaultLayout>
  );
};
export default Report;
