import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = 'leaves';

const LeaveRepository = {
    getLeaveStatus(params: { start: number; end: number; teamIds: string;}) {
      return BaseRepository.get(`teams/${resource}`, {
        params,
      });
    },
    getLeaveStatusEmployee(params: { start: number; end: number; teamIds: string; startYearDate: number, endYearDate: number}) {
      return BaseRepository.get(`users/teams/${resource}`, {
        params,
      });
    },
  };
  export default LeaveRepository;

