import { useState, useEffect } from 'react';
import Modal from './Modal';
import classes from './style/AttendanceData.module.scss';
import { UserSatus, UserAttandance } from '../../modules/Leave/types/type';
import { useTranslation } from '../../providers/locale-provider';
import { setShortText } from '../../core/helpers/utils';
import { convertTime } from '../../modules/TimeSheet/actions/timesheet';
import Table from './Table';
import { getDate } from '../../modules/TimeSheet/actions/timesheet';
import Tooltip from '@mui/material/Tooltip';
import BaseButton from './BaseButton';
import moment from 'jalali-moment';

interface IAvatarDetails {
  exitModal: () => void;
  userAttendanceData: UserSatus;
  attandanceId: number;
}
const AttendanceData = ({ exitModal, userAttendanceData, attandanceId }: IAvatarDetails) => {
  const { t9n } = useTranslation();

  const [attandance, setAttandance] = useState<UserAttandance>();
  const [totalDuration, setTotalDuration] = useState<string>();

  const convertToMin = (duration: number) => {
    return Math.floor((duration % 3600) / 60).toString() + 'm';
  };

  const setAttandanceTime = (duration: string) => {
    if (+duration % 3600 === 0) return (+duration / 3600).toString() + ' h ';
    else if (+duration < 3600) return convertToMin(+duration);
    else return Math.floor(+duration / 3600).toString() + 'h ' + convertToMin(+duration);
  };

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (attandance?.attendanceProjects) {
      attandance?.attendanceProjects.forEach((projectAttandance) => {
        dataArray.push({
          items: [
            <div>
              <Tooltip title={<section>{projectAttandance.project.name}</section>} placement="bottom-start" arrow>
                <section>{setShortText({ text: projectAttandance.project.name, length: 25 })}</section>
              </Tooltip>
            </div>,
            <div>{setAttandanceTime(projectAttandance.duration)}</div>,
            <div>
              <Tooltip title={<section>{projectAttandance.description}</section>} placement="bottom-start" arrow>
                <section>{setShortText({ text: projectAttandance.description, length: 45 })}</section>
              </Tooltip>
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  const different = (timeA: number, timeB: number) => {
    const moment1 = moment(timeA);
    const moment2 = moment(timeB);

    let hours = 0;
    let minutes = 0;
    const duration = moment.duration(moment2.diff(moment1));
    hours = Math.floor(duration.asHours());
    minutes = Math.floor(duration.asMinutes()) % 60;
    return hours && minutes
      ? hours + ' h ' + minutes + ' m'
      : hours && !minutes
      ? hours + ' h'
      : minutes + ' m';
  };

  useEffect(() => {
    const attandanceData = userAttendanceData.userAttandance.find((attandance) => attandance.id === attandanceId);
    if (attandanceData) {
      setAttandance(attandanceData);
      const diffTime = different(+attandanceData?.check_in_datetime * 1000, +attandanceData?.check_out_datetime * 1000);
      setTotalDuration(diffTime);
    }
  }, [attandanceId]);

  return (
    <Modal
      size={'lg'}
      header={t9n.details_text}
      smallHeader={true}
      body={
        <div className={classes.modalBody}>
          <div className={classes.info}>
            <div className={classes.username}>{`${userAttendanceData.name} ${userAttendanceData.last_name}`}</div>
            <div className={classes.email}>{userAttendanceData.email}</div>
          </div>
          <hr />
          <div className={classes.attendaceData}>
            <div className={classes.attendaceDataCel}>
              <div>
                <span className={classes.projectTime}>
                  {convertTime(attandance?.check_in_datetime)}, {getDate(attandance?.check_in_datetime)}
                </span>
              </div>
              <span className={classes.projectText}>{t9n.first_punch_in}</span>
            </div>
            <div className={classes.attendaceDataCel}>
              <div>
                <span className={classes.projectTime}>
                  {convertTime(attandance?.check_out_datetime)}, {getDate(attandance?.check_out_datetime)}
                </span>
              </div>
              <span className={classes.projectText}>{t9n.last_punch_out}</span>
            </div>
            <div className={classes.attendaceDataCel}>
              <span className={classes.projectTime}>{totalDuration}</span>
              <span className={classes.projectText}>{t9n.total_Duration_text}</span>
            </div>
          </div>
          <Table
            buttonHeader={false}
            textCenter={false}
            label={''}
            message={'There is no project description!'}
            headers={[
              <span className={classes.tableHeader}>{t9n.project_name_text}</span>,
              <span className={classes.tableHeader}>{t9n.duration_text}</span>,
              <span className={classes.tableHeader}>{t9n.leave_description_text}</span>,
            ]}
            data={sendDataForTable()}
          />
        </div>
      }
      action={
        <div className={classes.buttonBox}>
          <BaseButton
            hasBlock={true}
            size="lg"
            title={t9n.ok_text}
            color="primary"
            hasRightIcon={false}
            hasLeftIcon={false}
            onClickHandler={exitModal}></BaseButton>
        </div>
      }
      exitAction={exitModal}
    />
  );
};

export default AttendanceData;
