import { useEffect, useState } from 'react';
import { useTranslation } from '../../providers/locale-provider';
import { CaptionProps, DateRange, DayPicker } from 'react-day-picker';
import { convertDate } from '../../core/helpers/FormatDate';
import { addMonths, format, subMonths } from 'date-fns';
import { OptionalUndefined } from '../../types/sharedTypes';

import classes from './style/DatePicker.module.scss';
import BackIcon from '../../assets/image/Icons/chevron_left.svg';
import NextIcon from '../../assets/image/Icons/chevron_right.svg';
import calendar from '../../assets/image/Icons/calendar.svg';

import Input from './Input';
import { IAppLocale } from '../../locales';
import { isDefined } from '../../core/helpers/utils';
import { errorActions } from '../../store/error-slice';
import { useDispatch } from 'react-redux';

export enum DatePickerPosition {
  TOP_Right = ' top-0 end-0',
  RIGHT_Center = ' top-50 start-50',
  LEFT_Center = ' top-50 end-0',
  TOP_Center = ' top-0 start-50',
  BOTTOM_Right = 'bottom-50 end-0',
}

export enum DatePickerSize {
  SMALL = '44px',
  MEDIUM = '52px',
}

interface DatePickerProps {
  defaultDate?: Date;
  datePickerPosition?: DatePickerPosition;
  selectedDate: Date | undefined | DateRange;
  handleSelectedDate?: (date: Date) => void;
  handleSelectedDateRange?: (date: OptionalUndefined<DateRange>) => void;
  title?: string;
  datePickerSize?: DatePickerSize;
  isDateRange?: boolean;
  disabled?: Date;
  isVisible?: boolean;
  onClick?: () => void;
  disableAll?: boolean;
  isInvalid?: boolean;
  inValidmsg?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  defaultDate,
  datePickerPosition,
  selectedDate,
  handleSelectedDate,
  handleSelectedDateRange,
  title,
  datePickerSize,
  isDateRange,
  disabled,
  isVisible = true,
  onClick,
  disableAll,
  isInvalid,
  inValidmsg,
}) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedDateLocal, setSelectedDateLocal] = useState<OptionalUndefined<Date | DateRange>>(
    selectedDate || new Date()
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [monthForNormalDatePicker, setMonthForNormalDatePicker] = useState<Date>(
    (selectedDateLocal || selectedDate || new Date()) as Date
  );
  const monthForNormalDatePickerVal = monthForNormalDatePicker || selectedDateLocal || selectedDate || new Date();
  const selectedDateVal = selectedDateLocal || selectedDate || new Date();
  const [monthForRangeDatePicker, setMonthForRangeDatePicker] = useState<Date>(
    ((selectedDateLocal as DateRange)?.from || (selectedDate as DateRange)?.from || new Date()) as unknown as Date
  );

  useEffect(() => {
    if (showDatePicker) {
      resetDatePickerChanges();
    }
  }, [showDatePicker]);

  const nextMonth = () => {
    if (isDateRange) {
      setMonthForRangeDatePicker(addMonths(monthForRangeDatePicker, 1));
      return;
    }
    setMonthForNormalDatePicker(addMonths(monthForNormalDatePickerVal, 1));
  };
  const previousMonth = () => {
    if (isDateRange) {
      setMonthForRangeDatePicker(subMonths(monthForRangeDatePicker, 1));
      return;
    }
    setMonthForNormalDatePicker(subMonths(monthForNormalDatePickerVal, 1));
  };
  const goToMonth = (input: OptionalUndefined<Date> | DateRange) => {
    if (isDateRange) {
      setMonthForRangeDatePicker((input as DateRange)?.from || new Date());
      return;
    }
    setMonthForNormalDatePicker((input as Date) || new Date());
  };
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
    onClick && onClick();
  };

  useEffect(() => {
    if (!isVisible) setShowDatePicker(false);
  }, [isVisible]);

  const convertSelectedDate = () => {
    if (isDateRange) {
      return setDateRange(selectedDateVal as DateRange);
    }
    const formattedDate = (selectedDateVal as Date)?.getTime();
    return convertDate('MMM D,YYYY', formattedDate);
  };

  const cancelDatePickerChanges = () => {
    resetDatePickerChanges();
    toggleDatePicker();
  };

  const resetDatePickerChanges = () => {
    goToMonth(selectedDate);
    setSelectedDateLocal(selectedDate || new Date());
  };

  const applyDatePickerChanges = () => {
    setIsDateSelected(true);
    setShowDatePicker(false);
    if (handleSelectedDate) {
      handleSelectedDate(selectedDateLocal as Date);
    }
    if (isDateRange && handleSelectedDateRange) {
      if (!isDefined((selectedDateLocal as DateRange).from) || !isDefined((selectedDateLocal as DateRange).to)) {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            state: 'error',
            message: 'Please select two dates for the start and end.',
          })
        );
        return;
      }
      handleSelectedDateRange(selectedDateLocal as DateRange);
    }
  };

  const handleDatePickerChanges = (date: OptionalUndefined<Date | DateRange>) => setSelectedDateLocal(date);

  const today = () => {
    const currentDate = new Date();
    goToMonth(currentDate);
    handleDatePickerChanges(currentDate);
  };

  const setDateRange = (selectedDate: DateRange) => {
    const from = selectedDate.from?.getTime() && convertDate('MMM D', selectedDate.from?.getTime());
    const to = selectedDate.to?.getTime() && convertDate('MMM D', selectedDate.to?.getTime());
    if (!isDefined(from) && !isDefined(to)) {
      return convertDate('MMM D', new Date().getTime());
    }
    return `${from ?? '...'} - ${to ?? '...'}`;
  };

  const setTitle = () => {
    if (isDateRange && selectedDate) return setDateRange(selectedDate as DateRange);
    if (selectedDate) return convertDate('MMM D', (selectedDate as Date).getTime());
    if (defaultDate) return convertDate('MMM D', defaultDate.getTime());
    if (title) return title;

    return t9n.select_date_text;
  };

  return (
    <>
      <button
        onClick={toggleDatePicker}
        className={`${classes.selectDate} w-100 d-flex justify-content-center align-items-center mb-2${
          isDateSelected && 'text-dark'
        }`}
        style={datePickerSize ? { height: datePickerSize } : { height: DatePickerSize.SMALL }}>
        <img src={calendar} alt="calendar icon" className={`me-2`} />
        {setTitle()}
      </button>
      {isInvalid && (
        <span className={classes.datePickerError}>{inValidmsg}</span>
      )}
      {!disableAll && isVisible && showDatePicker && (
        <div className={`${classes.datePicker} ${datePickerPosition}`}>
          {isDateRange ? (
            <DayPicker
              selected={selectedDateVal as DateRange}
              onSelect={handleDatePickerChanges}
              components={{
                Caption: (props) =>
                  DatePickerHeader({
                    caption: props,
                    previousMonth: previousMonth,
                    nextMonth: nextMonth,
                    today: today,
                    convertSelectedDate: convertSelectedDate,
                  }),
              }}
              onMonthChange={goToMonth}
              month={monthForRangeDatePicker}
              showOutsideDays
              mode="range"
            />
          ) : (
            <DayPicker
              selected={selectedDateVal as Date}
              onDayClick={handleDatePickerChanges}
              components={{
                Caption: (props) =>
                  DatePickerHeader({
                    caption: props,
                    previousMonth: previousMonth,
                    nextMonth: nextMonth,
                    today: today,
                    convertSelectedDate: convertSelectedDate,
                  }),
              }}
              onMonthChange={goToMonth}
              month={monthForNormalDatePickerVal}
              showOutsideDays
              disabled={disabled ? [{ after: disabled }] : []}
            />
          )}

          <DatePickerFooter t9n={t9n} apply={applyDatePickerChanges} cancel={cancelDatePickerChanges} />
        </div>
      )}
    </>
  );
};
export default DatePicker;

interface IDatePickerHeader {
  caption: CaptionProps;
  previousMonth: () => void;
  nextMonth: () => void;
  today: () => void;
  convertSelectedDate: () => OptionalUndefined<string>;
}

const DatePickerHeader = ({ caption, nextMonth, previousMonth, today, convertSelectedDate }: IDatePickerHeader) => {
  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <button disabled={false} onClick={previousMonth} className={`${classes.arrowDate}`}>
          <img src={BackIcon} alt="" />
        </button>
        <p className={`${classes.date} mb-0`}> {format(caption.displayMonth, 'MMM yyy')}</p>
        <button onClick={nextMonth} className={`${classes.arrowDate}`}>
          <img src={NextIcon} alt="" />
        </button>
      </div>
      <div className="d-flex">
        <div className={`${classes.selectedDateInput}`}>
          <Input name="date" size="sm" readOnly={true} value={convertSelectedDate()} />
        </div>
        <div className={`${classes.todayDate}`}>
          <button onClick={today}>Today</button>
        </div>
      </div>
    </div>
  );
};

interface IDatePickerFooter {
  t9n: IAppLocale;
  apply: () => void;
  cancel: () => void;
}

export const DatePickerFooter = ({ t9n, apply, cancel }: IDatePickerFooter) => {
  return (
    <div className={`${classes.footer} d-flex align-items-center gap-4`}>
      <div className="w-50">
        <button className={`${classes.cancel} w-100`} onClick={cancel}>
          {t9n.cancel_text}
        </button>
      </div>
      <div className="w-50">
        <button className={`${classes.apply} w-100`} onClick={apply}>
          {t9n.apply_text}
        </button>
      </div>
    </div>
  );
};
