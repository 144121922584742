import classes from '../style/Auth.module.scss';
import Input from '../../../../components/base/Input';
import BaseButton from '../../../../components/base/BaseButton';
import { useState } from 'react';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import Loading from '../../../../components/base/Loading';
import Toast from '../../../../components/base/Toast';
import AuthLayout from '../../../../view/layouts/AuthLayout';
import { useTranslation } from '../../../../providers/locale-provider';
import { RepoType } from '../../../../types/sharedTypes';
import { isValidEmail } from '../../../../core/helpers/FormValidation';

const authRepository = () => RepoFactory.get(RepoType.Auth);

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t9n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [inValidmsg, setInValidmsg] = useState<string>();
  const emialOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setEmailError(false);
    setEmail(e.currentTarget.value);
  };
  const goToNextPage = async (link: string) => navigate(link);
  const handleToastClose = () => setShowToast(false);

  const resetPass = async () => {
    setEmailError(false);
    if (email.length && isValidEmail(email)) {
      setLoading(true);
      let body = {
        email,
      };
      authRepository()
        .resetPass(body)
        .then(async (res: AxiosResponse<any>) => {
          setShowToast(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              setInValidmsg(t9n.email_not_found);
              setEmailError(true);
            } else {
              dispatch(errorActions.setHasError(true));
              dispatch(errorActions.setError(error.response?.data));
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      !email.length ? setInValidmsg(t9n.enter_emial_text) : setInValidmsg(t9n.invalid_email_error);
      setEmailError(true);
    }
  };

  return (
    <AuthLayout headerText={t9n.reset_password_text} subHeader={t9n.reset_password_desc}>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.emial_successfully_sent} onClose={handleToastClose} />}
      <div className={classes.formSection}>
        <Input
          name="email"
          placeholder={t9n.email_placeholder_text}
          title={t9n.email_text}
          handleChange={emialOnChangeHandler}
          isInvalid={emailError}
          inValidmsg={inValidmsg}
          autoComplete={'off'}
        />
        <BaseButton title={t9n.reset_password_request_btn} hasBlock={true} onClickHandler={resetPass} />
        <BaseButton
          title={t9n.back_to_login_btn_text}
          color="white-black"
          hasBlock={true}
          onClickHandler={() => goToNextPage('/login')}
        />
      </div>
    </AuthLayout>
  );
};
export default PasswordRecovery;
