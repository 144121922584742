import React, { useState } from 'react';
import classes from './style/ToggleSwitch.module.scss';

{
  /* <ToggleSwitch onChange={handleToggleChange} /> */
}

// // use it in component
// const handleToggleChange = (checked: boolean) => {
//     console.log(`Toggle switch is now ${checked ? 'on' : 'off'}`);
//   };

interface ToggleSwitchProps {
  defaultChecked?: boolean;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  disable?:boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ defaultChecked = false, onChange, checked, disable }) => {
  const [isChecked, setChecked] = useState(checked ?? defaultChecked);

  const handleToggle = () => {
    if(disable) return;
    const newCheckedState = !isChecked;
    setChecked(newCheckedState);
    onChange(newCheckedState);
  };

  return (
    <div className={`${classes.switch} ${disable ? classes.off : (checked ?? isChecked ? classes.on : classes.off)}`} onClick={handleToggle}>
      <div className={classes.slider} />
    </div>
  );
};

export default ToggleSwitch;
