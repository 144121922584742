import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import classes from './style/EditProject.module.scss';
import layoutclasses from '../../pages/style/Project.module.scss';
import projectClasses from '../style/ProjectBaseStyles.module.scss';

import BaseTitle from '../../../../components/base/BaseTitle';
import AssignEmployee from './AssignEmployee';
import Table from '../../../../components/base/Table';
import EditableField from './EditableField';
import DeleteEmployee from './DeleteEmployee';
import ToggleSwitch from '../../../../components/base/ToggleSwitch';
import Toast from '../../../../components/base/Toast';
import Loading from '../../../../components/base/Loading';
import Search from '../../../../components/base/Search';
import minus from '../../../../assets/image/Icons/minus.svg';

import { RootState } from '../../../../store';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { errorActions } from '../../../../store/error-slice';
import { RepoType } from '../../../../types/sharedTypes';

import { Project, ProjectEmployee } from '../types/type';
import { useTranslation } from '../../../../providers/locale-provider';
import SettingLayout from '../../../../view/layouts/SettingLayout';
import Fullname from '../../../../components/base/Fullname';
import { setShortText } from '../../../../core/helpers/utils';

const projectRepository = () => RepoFactory.get(RepoType.Project);

const EditProject = () => {
  const { t9n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const [loading, setLoading] = useState<boolean>(false);
  const [project, setProject] = useState<Project>();
  const [employeesList, setEmployeesList] = useState<ProjectEmployee[]>();
  const [employeeDeleted, setEmployeeDeleted] = useState<boolean>(false);
  const [addNewEmployee, setAddNewEmployee] = useState<boolean>(false);
  const [isProjectInfoChanged, setIsProjectInfoChanged] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => setDebouncedSearchTerm(debouncedSearchTerm);

  const handleToggleChange = (id: number, checked: boolean) => {
    const description = checked ? 1 : 0;
    updateEmployeeDescription(id, description);
  };

  const handleToastClose = () => setShowToast(false);

  function resetEmployeeStatus() {
    if (employeeDeleted) {
      setEmployeeDeleted(false);
      setToastMessage('Employee has been deleted.');
      setShowToast(true);
    }
    if (addNewEmployee) {
      setAddNewEmployee(false);
      setToastMessage('Employee has been assigned.');
      setShowToast(true);
    }
  }

  const getProject = () => {
    setLoading(true);

    projectRepository()
      .getByIdProject(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setProject(res.data.showProject);
        resetEmployeeStatus();
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getAllProjectEmployees = () => {
    setLoading(true);
    setEmployeesList([]);

    projectRepository()
      .getAllProjectEmployees({ page: 1, pageSize: 100, search: debouncedSearchTerm }, id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setEmployeesList(res.data.data);
        resetEmployeeStatus();
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const updateEmployeeDescription = (userId: number, description: number) => {
    setLoading(true);
    const body = {
      userId: userId,
      description: description,
    };

    projectRepository()
      .updateEmployeeDescription(body, id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleNewEmployeeAssign = (status: boolean) => setAddNewEmployee(status);
  const handleDeleteEmployee = (status: boolean) => setEmployeeDeleted(status);

  useEffect(() => {
    getProject();
  }, [addNewEmployee, employeeDeleted, isProjectInfoChanged]);

  useEffect(() => {
    getAllProjectEmployees();
  }, [addNewEmployee, employeeDeleted, debouncedSearchTerm]);

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (employeesList?.length) {
      employeesList.forEach((employee: ProjectEmployee) => {
        dataArray.push({
          items: [
            <div className={classes.employeeInfo}>
              <span className={classes.info}>
                <Fullname firstname={employee.user.name} lastname={employee.user.last_name} />
                <div className={classes.employeeEmail} title={employee.user.email}>
                  {setShortText({ text: employee.user.email, length: 15 }) ?? <img src={minus} alt="minus" />}
                </div>
              </span>
              <div className={classes.toggleSwitch}>
                <ToggleSwitch
                  defaultChecked={employee.description === 1}
                  onChange={(checked) => handleToggleChange(employee.userId, checked)}
                />
              </div>
            </div>,
            <div className={classes.actions}>
              <DeleteEmployee projectId={id} employeeId={employee.userId} handleDeleteEmployee={handleDeleteEmployee} />
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  return (
    <SettingLayout>
      <Loading loading={loading} />
      {showToast && <Toast message={toastMessage} onClose={handleToastClose} />}
      <div className={layoutclasses.projectLayout}>
        <div className={classes.editProjestHeader}>
          <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
          <AssignEmployee handleNewEmployeeAssign={handleNewEmployeeAssign} project={project} />
        </div>
        {project && (
          <>
            <div className={classes.projectInfo}>
              <EditableField projectId={id} value={project.name} isName={true} />
              <EditableField projectId={id} value={project.description} isName={false} />
            </div>

            <Table
              buttonHeader={false}
              label={''}
              message={debouncedSearchTerm ? t9n.nothing_found_text : t9n.no_employees}
              headers={[
                <div className={classes.headerText}>
                  <span>{t9n.employee_text}</span>
                  <span>{t9n.require_work_desc_text}</span>
                </div>,
                '',
              ]}
              data={sendDataForTable()}
            />
          </>
        )}
      </div>
    </SettingLayout>
  );
};
export default EditProject;
