import { useEffect, useState } from 'react';
import Loading from '../../../components/base/Loading';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import './style/VisTimeline.css';
import classes from './style/LeaveTimeline.module.scss';
import chevron_left from '../../../assets/image/Icons/chevron_left.svg';
import chevron_right from '../../../assets/image/Icons/chevron_right.svg';
import { useLeaveTimeline } from '../hooks/useLeaveTimeline';
import { Team } from '../../../types/sharedTypes';
import AvatarDetails from '../../../components/base/AvatarDetails';
import AttendanceData from '../../../components/base/AttendanceData';
import { UserSatus } from '../types/type';
import { InitialUsersLeaveStatus } from '../hooks/useLeaveTimeline';

interface LeaveTimelineProps {
  userRole: string;
  selectedTeam: Team[];
  order: string;
  debouncedSearchTerm: string;
}

export interface SelectedAvatarDetails {
  src: string;
  name: string | null;
  email: string;
  active: boolean;
}

const LeaveTimeline = (props: LeaveTimelineProps) => {
  const [showAvatarDetails, setShowAvatarDetails] = useState<boolean>(false);
  const [showAttendanceData, setShowAttendanceData] = useState<boolean>(false);
  const [userAttendanceData, setUserAttendanceData] = useState<UserSatus>(InitialUsersLeaveStatus[0]);
  const [attandanceId, setAttandanceId] = useState<number>(0);
  const [selectedAvatarDetails, setSelectedAvatarDetails] = useState<SelectedAvatarDetails>();

  const onAvatarClick = (src: string, fullname: string | null, email: string, isActive: boolean) => {
    setSelectedAvatarDetails({
      src: src,
      name: fullname,
      email: email,
      active: isActive,
    });
    setShowAvatarDetails(true);
  };

  const onAttendanceClick = (itemId: number, user: UserSatus) => {
    if (user) {
      setUserAttendanceData(user);
      setAttandanceId(itemId);
    }
    setShowAttendanceData(true);
  };

  const { loading, usersLeaveStatus, months, startDate, timelineRef, getMonths, createTimeline, updateWeek, userData } =
    useLeaveTimeline({
      userRole: props.userRole,
      selectedTeam: props.selectedTeam,
      order: props.order,
      onAvatarClick: onAvatarClick,
      onAttendanceClick: onAttendanceClick,
      debouncedSearchTerm: props.debouncedSearchTerm,
    });

  const closeAvatarModal = () => setShowAvatarDetails(false);
  const closeAttendanceModal = () => setShowAttendanceData(false);

  useEffect(() => {
    userData && createTimeline();
  }, [usersLeaveStatus, userData]);

  useEffect(() => {
    getMonths();
  }, [startDate]);

  return (
    <>
      <Loading loading={loading} />
      {showAvatarDetails && (
        <AvatarDetails
          src={selectedAvatarDetails?.src || undefined}
          exitModal={closeAvatarModal}
          selectedAvatarDetails={selectedAvatarDetails}
        />
      )}
      {showAttendanceData && (
        <AttendanceData
          exitModal={closeAttendanceModal}
          attandanceId={attandanceId}
          userAttendanceData={userAttendanceData}
        />
      )}
      <div className={classes.timeLineControler}>
        <img className={classes.chevron} src={chevron_left} alt="left" onClick={() => updateWeek('prev')} />
        <div>{months}</div>
        <img className={classes.chevron} src={chevron_right} alt="right" onClick={() => updateWeek('next')} />
      </div>
      <div ref={timelineRef} className={classes.timelineBody} />
    </>
  );
};

export default LeaveTimeline;
