import Modal from '../../../components/base/Modal';
import classes from './style/RequestModal.module.scss';
import { RequestStatus, Request } from './types/type';
import TextArea from '../../../components/base/TextArea';
import BaseButton from '../../../components/base/BaseButton';
import Checkbox from '../../../components/base/Checkbox';

import { useTranslation } from '../../../providers/locale-provider';
import { useState } from 'react';
interface IRequestModal {
  request: Request;
  exitModal: () => void;
  requestStatus: string;
  sendRequestStatus: (status: string, comment?: string, optional_comment_modal?: string) => void;
}

const RequestModal = (props: IRequestModal) => {
  const defaultComment = props.request.optional_comment_modal && props.requestStatus === RequestStatus.Approved
    ? ` با سلام\n ${
        props.request.reason === 'Sick Leave' ? 'ضمن آرزوی بهبودی برای شما،\n' : ''
      } لطفا بازه مرخصی خود را به اطلاع مدیران مستقیم خود (داخلی یا سمت مشتری) برسانید. \nهمچنین در کانال اسلک پروژه هایی  که دخیل هستید هم بازه زمانی مرخصی تون را اعلام کنید.\n با آرزوی سلامتی و موفقیت روزافزون`
    : '';

  const { t9n } = useTranslation();
  const [comment, setComment] = useState<string>(defaultComment);
  const [optionalComment, setOptionalComment] = useState<boolean>(false);
  const [optionalCommentFlag, setOptionalCommentFlag] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setOptionalCommentFlag(true);
    setOptionalComment(!optionalComment);
  };

  const commentOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setComment(e.currentTarget.value);

  const addStatus = () => {
    props.exitModal();
    if (optionalCommentFlag) {
      const optional_comment_modal = optionalComment ? 'false' : 'true';
      props.sendRequestStatus(props.requestStatus, comment, optional_comment_modal);
    } else {
      props.sendRequestStatus(props.requestStatus, comment);
    }
  };

  return (
    <Modal
      header={`${props.requestStatus === RequestStatus.Approved ? t9n.approve_text : t9n.deny_text} ${
        t9n.time_off_request_text
      }`}
      body={
        <div className={classes.modalBody}>
          <TextArea
            name={t9n.comment_text}
            placeholder={t9n.enter_comment_text}
            title={t9n.comment_text}
            required={false}
            rows={5}
            handleChange={commentOnChangeHandler}
            value={comment}
          />
          <div className={classes.optionalComment}>
            <Checkbox onChange={handleCheckboxChange} checked={optionalComment} />
            <div
              title="Adding comment is not mandatory for you.&#10;You can revert this change anytime you want in the profile page."
              className={classes.optionalCommentTxt}>
              Don't show me again.{' '}
            </div>
          </div>
        </div>
      }
      action={
        <div className={classes.buttonBox}>
          <BaseButton
            size={'lg'}
            hasBlock={true}
            title={`${props.requestStatus === RequestStatus.Approved ? t9n.approve_text : t9n.deny_text} ${
              t9n.and_text
            } ${t9n.save_btn_text}`}
            color="primary"
            hasRightIcon={false}
            hasLeftIcon={false}
            onClickHandler={addStatus}></BaseButton>
        </div>
      }
      exitAction={props.exitModal}
    />
  );
};

export default RequestModal;
