import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../../store/error-slice';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { Team, Employee, RepoType } from '../../../../types/sharedTypes';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../providers/locale-provider';
import { setShortText } from '../../../../core/helpers/utils';

import classes from './style/AddEmployee.module.scss';
import teamClasses from '../style/TeamBaseStyles.module.scss';

import Plus from '../../../../assets/image/Icons/Plus.svg';
import Checkbox from '../../../../components/base/Checkbox';
import BaseButton from '../../../../components/base/BaseButton';
import Loading from '../../../../components/base/Loading';
import Modal from '../../../../components/base/Modal';
import Table from '../../../../components/base/Table';
import minus from '../../../../assets/image/Icons/minus.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Fullname from '../../../../components/base/Fullname';

interface AddedEmployeesList {
  id: number;
}

interface AssignEmployeeProps {
  handleNewEmployeeAdd: (status: boolean) => void;
  team: Team | undefined;
  employeesList: Employee[];
}

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);
const TeamRepository = () => RepoFactory.get(RepoType.Teams);

const AddEmployee = (props: AssignEmployeeProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state: RootState) => state.auth.userInfo.user.defaultCompanyId);

  const [loading, setLoading] = useState<boolean>(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState<boolean>(false);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [addedEmployeesList, setAddedEmployeesList] = useState<AddedEmployeesList[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  let map = new Map<number, boolean>();

  const ButtonIcon: React.FC = () => <img src={Plus} alt="Plus" />;

  const openAddEmployeeModal = () => setAddEmployeeModal(true);
  const exitModal = () => {
    setAddEmployeeModal(false);
    setSelectedEmployees([]);
  };
  const handleCheckboxChange = (index: number) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(index) ? prevSelected.filter((item) => item !== index) : [...prevSelected, index]
    );
  };

  const getAllEmployees = () => {
    if (!hasMore) return;

    EmployeesRepository()
      .getCompanyUsers({ page: currentPage, pageSize: 10 }, companyId)
      .then((res: AxiosResponse<any>) => {
        setLoading(true);
        const data = res.data.data;
        if (data.length === 0) {
          setHasMore(false);
          setLoading(false);
        } else {
          const userIds = props.employeesList?.map((user) => user.id);
          const filteredEmployeesList: Employee[] = data.filter((item: Employee) => !userIds?.includes(item.id));
          if (!map.get(currentPage)) {
            setEmployeesList((prevList) => [...prevList, ...filteredEmployeesList]);
            if (filteredEmployeesList.length < 5) {
              setCurrentPage((prevPage) => prevPage + 1);
            }
          }
          map.set(currentPage, true); // Mark this page as fetched

          setLoading(false);
        }
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleAddEmployeeList = () => {
    let addedEmployees: AddedEmployeesList[] = [];
    selectedEmployees.forEach((index) => {
      const employee = employeesList[index];
      if (employee) {
        addedEmployees.push({
          id: employee.id,
        });
      }
    });
    setAddedEmployeesList(addedEmployees);
  };

  const addNewEmployees = () => {
    setLoading(true);
    handleAddEmployeeList();
    const body = {
      users: addedEmployeesList,
    };
    TeamRepository()
      .addEmployees(body, props.team?.id)
      .then((res: AxiosResponse<any>) => {
        props.handleNewEmployeeAdd(true);
        exitModal();
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleAddEmployeeList();
  }, [selectedEmployees]);

  useEffect(() => {
    props.team && addEmployeeModal && getAllEmployees();
  }, [currentPage]);

  useEffect(() => {
    if (!addEmployeeModal) {
      setCurrentPage(1);
      setEmployeesList([]);
      setHasMore(true);
      map.clear();
    } else {
      props.team && addEmployeeModal && getAllEmployees();
    }
  }, [addEmployeeModal]);

  const fetchMorePosts = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (employeesList.length) {
      employeesList.forEach((employee: Employee, index) => {
        dataArray.push({
          items: [
            <div className={classes.checkbox}>
              <Checkbox
                id={index.toString()}
                onChange={() => handleCheckboxChange(index)}
                checked={selectedEmployees.includes(index)}
              />
              <div className={classes.checkboxCell}></div>
              <span className={teamClasses.info}>
                <Fullname firstname={employee.name} lastname={employee.last_name} />
                <div className={teamClasses.employeeEmail} title={employee.email}>
                  {setShortText({ text: employee.email, length: 15 }) ?? <img src={minus} alt="minus" />}
                </div>
              </span>
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };
  const employeeListMsg = hasMore ? 'loading...' : t9n.no_employees;

  return (
    <>
      <Loading loading={loading} />

      <BaseButton
        title={t9n.add_employee_btn_text}
        color="primary"
        hasLeftIcon={true}
        icon={<ButtonIcon />}
        onClickHandler={openAddEmployeeModal}
      />

      {addEmployeeModal && (
        <Modal
          header={t9n.add_employee_btn_text}
          exitAction={exitModal}
          body={
            <div className={classes.employeeContainer} id="scrollableDiv">
              <div className={classes.modalBody}>
                <div className={classes.headerContainer}>
                  <div className={classes.tableHeader}>
                    <span className={classes.headerText + ' ' + classes.headerName}>{t9n.name_text}</span>
                  </div>
                </div>

                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={employeesList.length}
                  next={fetchMorePosts}
                  hasMore={hasMore}
                  loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}>
                  <Table
                    disableHeader={true}
                    buttonHeader={false}
                    textCenter={false}
                    label={''}
                    message={employeeListMsg}
                    headers={[<span className={classes.headerText + ' ' + classes.headerName}>{t9n.name_text}</span>]}
                    data={sendDataForTable()}
                  />
                </InfiniteScroll>
              </div>
            </div>
          }
          action={
            <div className={teamClasses.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.add_text}
                color="primary"
                onClickHandler={addNewEmployees}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default AddEmployee;
