import classes from './style/AddTeam.module.scss';
import teamClasses from './style/TeamBaseStyles.module.scss';
import Modal from '../../../components/base/Modal';
import BaseButton from '../../../components/base/BaseButton';
import Input from '../../../components/base/Input';
import Plus from '../../../assets/image/Icons/Plus.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { Employee, RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { useNavigate } from 'react-router-dom';

const TeamRepository = () => RepoFactory.get(RepoType.Teams);

interface MyComponentProps {
  handleNewTeam?: (status: boolean) => void;
  companyId: number;
  employeesList: Employee[];
}

const AddTeam = (props: MyComponentProps) => {
  const { t9n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAddTeamModal, setOpenAddTeamModal] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const ButtonIcon = () => {
    return <img src={Plus} alt="Plus" className={classes.ButtonIcon} />;
  };

  const openAddTeam = () => {
    setOpenAddTeamModal(true);
    setIsNameError(false);
  };

  const nameOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setIsNameError(false);
  };
  const exitModal = () => setOpenAddTeamModal(false);

  const createTeam = () => {
    if (name.length === 0) {
      setIsNameError(true);
      setLoading(false);
      return;
    } else {
      setIsNameError(false);
      setLoading(true);
      let body = {
        title: name,
        companyId: props.companyId,
      };
      TeamRepository()
        .createTeam(body)
        .then((res: AxiosResponse<any>) => {
          setLoading(false);
          exitModal();
          props.handleNewTeam && props.handleNewTeam(true);
          navigate(`/teams/edit/${res.data.id}`);
          setName('');
        })
        .catch((error: unknown) => {
          setLoading(false);
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
          }
        });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <BaseButton
        title={t9n.create_new_team_text}
        color="primary"
        hasLeftIcon={true}
        icon={ButtonIcon()}
        onClickHandler={openAddTeam}
      />

      {openAddTeamModal && (
        <Modal
          bodyOverFlow={true}
          header={t9n.create_new_team_text}
          exitAction={exitModal}
          body={
            <div className={teamClasses.modalBody}>
              <Input
                name="teamName"
                placeholder={t9n.name_text}
                title={t9n.team_name_text}
                required={true}
                handleChange={nameOnChangeHandler}
                isInvalid={isNameError}
                inValidmsg={t9n.team_name_invalid_msg_text}
                autoComplete={'off'}
              />
            </div>
          }
          action={
            <div className={teamClasses.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.create_btn_text}
                color="primary"
                onClickHandler={createTeam}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default AddTeam;
