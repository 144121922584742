import { Optional, OptionalOrMissing } from '../../types/sharedTypes';

export const isDefined = <T>(arg: T | null | undefined): arg is T => arg !== null && arg !== undefined;

export const getEnumByValue = <T>(list: Record<string, string>, value: OptionalOrMissing<string>): Optional<T> => {
  if (!isDefined(value)) {
    return null;
  }

  for (const enum_value of Object.values(list)) {
    if (enum_value === value) {
      return enum_value as unknown as T;
    }
  }
  return null;
};

export const capitalizeFirst = (input: string) => {
  if (!isDefined(input)) {
    return '';
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const capitalizeFirstOfEach = (input: string) => {
  if (!isDefined(input)) {
    return '';
  }
  return input
    .split(' ')
    .map((word) => {
      return capitalizeFirst(word);
    })
    .join(' ');
};

export const extractFirstOfEach = (input: string) => {
  if (!isDefined(input)) {
    return '';
  }
  return input
    .split(' ')
    .map((word) => {
      return word.charAt(0);
    })
    .join(' ');
};

interface SetShortTextParams {
  text: string | undefined | null;
  length: number;
  defaultValue?: string;
}

export const setShortText = ({ text, length }: SetShortTextParams) => {
  return !isDefined(text) || text === "" ? null : text.length > length ? text.substring(0, length) + '...' : text;
};

// calculate the time difference between two dates
export const getTimeDifference = ({ start, end }: { start: OptionalOrMissing<Date>, end: OptionalOrMissing<Date> }) => {
  const startDate = new Date(start!);
  const endDate = new Date(end!);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert the time difference to hours, minutes
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (isNaN(hours) || isNaN(minutes)) {
      return undefined
  }

  return `${hours}h ${minutes}m`
}

export const secondsToHourMinute = (seconds: number) => {
  const hours = Math.floor(seconds / 3600); // 3600 seconds = 1 hour
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60); // 60 seconds = 1 minute

  return { hours, minutes };
}

export const isValidDate = (date: any) => {
  return !isNaN(new Date(date).getTime());
}

export const capitalizeWords = (input: string | null) => {
  return input && input.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const setvalidname = (
  isFullname: string | null,
  firstname: string | null,
  lastname: string | null,
  fullname: string
) => {
  if (isFullname) {
    return setShortText({ text: fullname, length: 15 });
  } else if (firstname) {
    return setShortText({ text: firstname, length: 8 });
  } else if (lastname) {
    return setShortText({ text: lastname, length: 8 });
  } else {
    return null;
  }
};

export const calculateLeaves = (leavesTime: number, workingTime: number) => {
  const isPositive = leavesTime >= 0;
  const days = Math.floor(leavesTime / (workingTime * 3600));
  const hours = Math.floor((leavesTime - days * (workingTime * 3600)) / 3600);
  const minutes = (leavesTime - days * (workingTime * 3600) - hours * 3600) / 60;
  return { days, hours, minutes, isPositive };
};