import { useState } from 'react';
import classes from './style/Input.module.scss';
import Form from 'react-bootstrap/Form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
interface MyComponentProps {
  title?: string;

  placeholder?: string;
  id?: string | undefined;
  name: string;
  value?: string | number;

  onChange?: ((e: React.FormEvent<HTMLInputElement>) => void) | undefined;
  icon?: JSX.Element | JSX.Element[];
  disable?: boolean;
  pattern?: string;
  handleChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  handleEnter?: ((e: React.KeyboardEvent<HTMLInputElement>) => void) | undefined;
  required?: boolean;
  isInvalid?: boolean;
  inValidmsg?: string;
  autoComplete?: string;
}

const InputPassword = (props: MyComponentProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={classes.inputbox}>
      {props.title && (
        <Form.Label>
          {props.title}
          {props.required === false && <span className={classes.labelRequired}>optional</span>}
        </Form.Label>
      )}
      <Form.Control
        className={`${classes.input}`}
        type={showPassword ? 'text' : 'password'}
        placeholder={props.placeholder}
        id={props.id ? props.id : Math.random() + 'input'}
        value={props.value}
        disabled={props.disable}
        isInvalid={props.isInvalid}
        onChange={props.handleChange}
        required={props.required}
        onKeyDown={props.handleEnter}
        autoCapitalize='off'
        // autoComplete="new-password"
        autoComplete={props.autoComplete}
      />
      <button onClick={handleTogglePassword} className={`${classes.eyeButton}`}>
        {!showPassword ? (
          <VisibilityOffIcon className={`${classes.eyeIcon}`} />
        ) : (
          <VisibilityIcon className={`${classes.eyeIcon}`} />
        )}
      </button>
      <Form.Control.Feedback type="invalid">{props.inValidmsg}</Form.Control.Feedback>
    </div>
  );
};
export default InputPassword;
