import classes from './style/TimeOffModal.module.scss';
import close from '../../../assets/image/Icons/close.svg';
import BaseButton from '../../../components/base/BaseButton';
import { useTranslation } from '../../../providers/locale-provider';
import up from '../../../assets/image/Icons/new-arrow-up.svg';
import down from '../../../assets/image/Icons/new-arrow-down.svg';
import { useEffect, useState } from 'react';
import { ITimeOff } from './EditEmployee';
import Info from '../../../components/base/Info';
import { calculateLeaves } from '../../../core/helpers/utils';

interface ModalComponents {
  isIncrease: boolean;
  exitAction: () => void;
  handleSave: (editedTimeOff: ITimeOff) => void;
  timeOff: ITimeOff;
}

const TimeOffModal = (props: ModalComponents) => {
  const { t9n } = useTranslation();
  const [editedTimeOff, setEditedTimeOff] = useState<ITimeOff>(props.timeOff);
  const [editNumbers, setEditNumbers] = useState<ITimeOff>({ days: 0, hours: 0, minutes: 0, isPositive: true });

  const exitHandler = () => {
    if (props.exitAction) return props.exitAction();
  };

  const handleEdit = (editType: keyof ITimeOff, value: number, isInput: boolean) => {
    if (editType === 'hours' && value > 7 && isInput) {
      return;
    }
    if (editType === 'minutes' && value > 59 && isInput) {
      return;
    }

    let days = editNumbers.days;
    let hours = editNumbers.hours;
    let min = editNumbers.minutes;

    if (editType === 'days') {
      days = isInput ? value : days + value;
    } else if (editType === 'hours') {
      hours = isInput ? value : hours + value;
      if (hours > 7) {
        hours %= 8;
        days += 1;
      }
    } else {
      min = isInput ? value : min + value;
      if (min > 59) {
        min %= 59;
        hours += 1;
      }
    }

    if (days < 0 || hours < 0 || min < 0) {
      return;
    }

    let newTimeOff = structuredClone(props.timeOff);
    let newTimeOffSec = newTimeOff.days * 28800 + newTimeOff.hours * 3600 + newTimeOff.minutes * 60;
    newTimeOffSec = newTimeOff.isPositive ? newTimeOffSec : -newTimeOffSec;
    let editNumberSec = days * 28800 + hours * 3600 + min * 60;
    newTimeOffSec += props.isIncrease ? editNumberSec : -editNumberSec;

    const totalTimeOff = calculateLeaves(Math.abs(newTimeOffSec), 8);
    const isPositive = newTimeOffSec >= 0;
    setEditedTimeOff({
      days: totalTimeOff.days,
      hours: totalTimeOff.hours,
      minutes: totalTimeOff.minutes,
      isPositive: isPositive,
    });

    setEditNumbers({ days: days, hours: hours, minutes: min, isPositive: true });
  };

  return (
    <div className={classes.backgroundModal}>
      <div className={`${classes.mediumLarg} mh-100`}>
        <div className={classes.modalHeader}>
          <h1 className={`${classes.headerText}`}>
            <span className={props.isIncrease ? classes.increaseHeader : classes.decreaseHeader}>
              {props.isIncrease ? 'Increase ' : 'Decrease '}
            </span>
            {t9n.paid_time_off_per_year}
          </h1>
          <div className={classes.exitButton} onClick={(e) => exitHandler()}>
            <img src={close} alt="close icon" />
          </div>
        </div>
        <div className={classes.body}>
          <div>
            <div className={classes.paidTime}>
              <p className={classes.paidTimeText}>{t9n.main_paid_time_off_duration}</p>
              <p className={classes.paidTimeHour}>
                {props.timeOff.isPositive ? '' : '-'}
                {props.timeOff.days}d {props.timeOff.hours}h {props.timeOff.minutes}m
              </p>
            </div>
            <div className={classes.paidTime}>
              <p className={classes.paidTimeText}>{t9n.edited_paid_time_off_duration}</p>

              <p className={classes.paidTimeHour}>
                {editedTimeOff.isPositive ? '' : '-'}
                {editedTimeOff.days}d {editedTimeOff.hours}h {editedTimeOff.minutes}m
              </p>
            </div>
          </div>
          <Info
            smallText={true}
            text="Based on company policy (8-hour workday), hours cannot exceed 7, and minutes cannot exceed 59."
          />

          <div className={classes.edit}>
            <div className={classes.editfield}>
              <div className={classes.numberField}>
                <input
                  className={classes.number}
                  type="text"
                  value={editNumbers.days}
                  onChange={(e) => {
                    handleEdit('days', +e.target.value, true);
                  }}
                />
                <div className={classes.arrowBox}>
                  <img onClick={() => handleEdit('days', 1, false)} className={classes.arrowImg} src={up} alt="up" />
                  <img
                    onClick={() => handleEdit('days', -1, false)}
                    className={classes.arrowImg}
                    src={down}
                    alt="down"
                  />
                </div>
              </div>
              <div className={classes.fieldLabel}>D</div>
            </div>
            <div className={classes.editfield}>
              <div className={classes.numberField}>
                <input
                  className={classes.number}
                  type="text"
                  value={editNumbers.hours}
                  onChange={(e) => {
                    handleEdit('hours', +e.target.value, true);
                  }}
                />
                <div className={classes.arrowBox}>
                  <img onClick={() => handleEdit('hours', 1, false)} className={classes.arrowImg} src={up} alt="up" />
                  <img
                    onClick={() => handleEdit('hours', -1, false)}
                    className={classes.arrowImg}
                    src={down}
                    alt="down"
                  />
                </div>
              </div>
              <div className={classes.fieldLabel}>H</div>
            </div>
            <div className={classes.editfield}>
              <div className={classes.numberField}>
                <input
                  className={classes.number}
                  type="text"
                  value={editNumbers.minutes}
                  onChange={(e) => {
                    handleEdit('minutes', +e.target.value, true);
                  }}
                />
                <div className={classes.arrowBox}>
                  <img onClick={() => handleEdit('minutes', 1, false)} className={classes.arrowImg} src={up} alt="up" />
                  <img
                    onClick={() => handleEdit('minutes', -1, false)}
                    className={classes.arrowImg}
                    src={down}
                    alt="down"
                  />
                </div>
              </div>
              <div className={classes.fieldLabel}>Min</div>
            </div>
          </div>
        </div>

        <div className={classes.action}>
          <BaseButton
            hasBlock={true}
            size={'lg'}
            title={t9n.save_changes_btn_text}
            color="primary"
            hasRightIcon={false}
            hasLeftIcon={false}
            onClickHandler={() => props.handleSave(editedTimeOff)}></BaseButton>
        </div>
      </div>
    </div>
  );
};
export default TimeOffModal;
