import classes from './style/AddRequest.module.scss';
import BaseButton from '../../../components/base/BaseButton';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Plus from '../../../assets/image/Icons/Plus.svg';
import Modal from '../../../components/base/Modal';
import SelectInput from '../../../components/base/SelectInput';
import TextArea from '../../../components/base/TextArea';
import Input from '../../../components/base/Input';
import 'react-day-picker/src/style.css';
import { convertDate, nextDays, setTime } from '../../../core/helpers/FormatDate';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { requestActions } from '../store/request-slice';
import { errorActions } from '../../../store/error-slice';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { LeaveType, LeaveReason, LeaveStatus, LEAVE_TYPE_DATA } from '../types/type';
import { capitalizeFirst } from '../../../core/helpers/utils';
import { RepoType } from '../../../types/sharedTypes';
import { Option } from '../../../types/sharedTypes';
import { DatePickerPosition } from '../../../components/base/DatePicker';
import DatePicker from '../../../components/base/DatePicker';
import SelectTime from '../../../components/base/SelectTime';
import Toast from '../../../components/base/Toast';

const requestsRepository = () => RepoFactory.get(RepoType.Requests);

interface ChildProps {
  onRefresh: (data: number) => void;
}

const LeaveTypes = [LEAVE_TYPE_DATA[LeaveType.Daily], LEAVE_TYPE_DATA[LeaveType.Hourly]];
const AddRequest = ({ onRefresh }: ChildProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [openAddRequestModal, setOpenAddRequestModal] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<Option>(LEAVE_TYPE_DATA[LeaveType.Hourly]);
  const [leaveReason, setLeaveReason] = useState<string>();
  const [isLeaveReasonError, setIsLeaveReasonError] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [isTimeError, setIsTimeError] = useState<boolean>(false);
  const [isDurationError, setIsDurationError] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [forDays, setForDays] = useState<number>(1);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showToast, setShowToast] = useState(false);

  const handleForDays = (e: React.FormEvent<HTMLInputElement>) => {
    if (Number(e.currentTarget.value) >= 1) {
      setForDays(Number(e.currentTarget.value));
    }
  };

  const handleSelectedDate = (date: Date) => setSelectedDate(date);
  const handleLeaveReason = (selectedItem: Option) => setLeaveReason(selectedItem.value);
  const descriptionOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setDescription(e.currentTarget.value);
  const exitModal = () => setOpenAddRequestModal(false);
  const handleToastClose = () => setShowToast(false);

  const ButtonIcon = () => {
    return <img src={Plus} alt="Plus" className={classes.ButtonIcon} />;
  };
  const openAddRequest = () => {
    setSelectedDate(undefined);
    setFromDate('');
    setOpenAddRequestModal(true);
    setLeaveType(LEAVE_TYPE_DATA[LeaveType.Hourly]);
    setIsLeaveReasonError(false);
    setIsDateError(false);
    setIsTimeError(false);
    setIsDurationError(false);
    setForDays(1);
  };
  const leaveTypeOnChangeHandler = (selectedItem: Option) => setLeaveType(LEAVE_TYPE_DATA[selectedItem.value]);

  const createRequest = () => {
    if (!leaveReason) {
      setIsLeaveReasonError(true);
    }
    if (!selectedDate) {
      setIsDateError(true);
    }

    if (leaveType.value === LeaveType.Hourly) {
      if (!fromDate || !toDate) {
        setIsTimeError(true);
        return;
      }
      if (fromDate && toDate && fromDate > toDate) {
        setIsDurationError(true);
        return;
      }
    }

    if (selectedDate && leaveReason) {
      setLoading(true);
      let body;
      if (leaveType.value === LeaveType.Hourly) {
        const [fromHours, fromMinutes] = fromDate.split(':').map(Number);
        const [toHours, toMinutes] = toDate.split(':').map(Number);
        body = {
          start_datetime: setTime(fromHours, fromMinutes, convertDate('YYYY-MM-DD', selectedDate.getTime())),
          end_datetime: setTime(toHours, toMinutes, convertDate('YYYY-MM-DD', selectedDate.getTime())),
          description: description,
          type: leaveType.value,
          reason: leaveReason,
          request_status: LeaveStatus.Pending,
        };
      } else {
        body = {
          start_datetime: Math.floor(selectedDate.getTime() / 1000),
          end_datetime:
            forDays === 1 ? Math.floor(selectedDate.getTime() / 1000) : nextDays(selectedDate.getTime(), forDays - 1),
          description: description,
          type: leaveType.value,
          reason: leaveReason,
          request_status: LeaveStatus.Pending,
        };
      }
      requestsRepository()
        .createRequest(body)
        .then((result: AxiosResponse<any>) => {
          setLoading(false);
          exitModal();
          setRefreshKey((prevKey) => prevKey + 1);
          onRefresh(refreshKey);
          dispatch(requestActions.setSelectedTabNumber('pending'));
          dispatch(requestActions.setNewRequestAdded(true));
          setShowToast(true);
        })
        .catch((error: unknown) => {
          setLoading(false);
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
          }
        });
    }
  };
  useEffect(() => {
    if (leaveReason && isLeaveReasonError) {
      setIsLeaveReasonError(false);
    }
    if (selectedDate && isDateError) {
      setIsDateError(false);
    }
    if (toDate && fromDate) {
      setIsTimeError(false);
      if (toDate > fromDate) setIsDurationError(false);
    }
  }, [leaveReason, selectedDate, toDate, fromDate]);

  return (
    <div key={refreshKey}>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.sent_request} onClose={handleToastClose} />}
      <BaseButton
        title={t9n.new_request_btn_text}
        color="primary"
        hasLeftIcon={true}
        icon={ButtonIcon()}
        onClickHandler={openAddRequest}
      />
      {openAddRequestModal && (
        <Modal
          header={t9n.new_request_btn_text}
          exitAction={exitModal}
          body={
            <div className={classes.modalBody}>
              <SelectInput
                label={t9n.timeOff_type_text}
                options={LeaveTypes}
                defaultValue={leaveType}
                handleselectedOption={leaveTypeOnChangeHandler}
              />
              {leaveType.value === LeaveType.Hourly ? (
                <div className={`${classes.hourlySection} w-100`}>
                  <DatePicker
                    datePickerPosition={DatePickerPosition.TOP_Right}
                    handleSelectedDate={handleSelectedDate}
                    selectedDate={selectedDate}
                  />
                  {isDateError && <div className={classes.dateError}>{t9n.select_a_date_error}</div>}
                  <p className={`mb-1 mt-3 ${classes.timeOffDuratoin}`}>{t9n.timeOff_duration_text}</p>
                  <div className={`d-flex justify-content-between align-items-center`}>
                    <div className={`d-flex align-items-center`}>
                      <label htmlFor="from" className={`me-2 ${classes.labelTime}`}>
                        {t9n.leave_from_text}
                      </label>
                      <SelectTime fromDate={fromDate} setDate={setFromDate} />
                    </div>
                    <div className={`d-flex align-items-center`}>
                      <label htmlFor="to" className={`me-2 mx-2 ${classes.labelTime}`}>
                        {t9n.leave_to_text}
                      </label>
                      <SelectTime fromDate={fromDate} setDate={setToDate} />
                    </div>
                  </div>
                  {isTimeError ? (
                    <div className={classes.dateError}>{t9n.select_time_error}</div>
                  ) : isDurationError ? (
                    <div className={classes.dateError}>{t9n.time_duration_error}</div>
                  ) : null}
                </div>
              ) : (
                <div className={` w-100`}>
                  <p className={`mb-1 ${classes.timeOffDuratoin}`}>{t9n.timeOff_duration_text}</p>
                  <div className={`d-flex`}>
                    <div className={`w-50 d-flex align-items-center`}>
                      <label htmlFor="from" className={`me-2 ${classes.labelTime}`}>
                        {t9n.leave_from_text}
                      </label>
                      <DatePicker
                        datePickerPosition={DatePickerPosition.TOP_Right}
                        handleSelectedDate={handleSelectedDate}
                        selectedDate={selectedDate}
                      />
                    </div>
                    <div className={`w-50 d-flex align-items-center`}>
                      <label htmlFor="for" className={`me-2 mx-2 ${classes.labelTime}`}>
                        {t9n.leave_for_text}
                      </label>
                      <Input
                        type="number"
                        name="for"
                        handleChange={handleForDays}
                        value={forDays}
                        autoComplete={'off'}
                      />
                      <span className="mx-2">{capitalizeFirst(forDays > 1 ? t9n.days_text : t9n.day_text)}</span>
                    </div>
                  </div>
                  {isDateError && <div className={classes.dateError}>{t9n.select_a_date_error}</div>}
                </div>
              )}

              <SelectInput
                label={`${t9n.time_off_reason_text} *`}
                options={[
                  { value: LeaveReason.Vacation, title: t9n.leave_reason_vacation },
                  { value: LeaveReason.SickLeave, title: t9n.leave_reason_sick },
                  { value: LeaveReason.Academic, title: t9n.leave_reason_academic },
                  { value: LeaveReason.Other, title: t9n.leave_reason_other },
                ]}
                placeHolder={t9n.leave_reason_placeholder_text}
                handleselectedOption={handleLeaveReason}
                isInvalid={isLeaveReasonError}
                inValidmsg={t9n.leave_reason_error_text}
              />
              <TextArea
                name="description"
                placeholder={t9n.leave_description_placeholder_text}
                title={t9n.leave_description_text}
                required={false}
                rows={5}
                handleChange={descriptionOnChangeHandler}
              />
            </div>
          }
          action={
            <div className={classes.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.send_request_btn_text}
                color="primary"
                hasRightIcon={false}
                hasLeftIcon={false}
                onClickHandler={createRequest}></BaseButton>
            </div>
          }></Modal>
      )}
    </div>
  );
};
export default AddRequest;
