import classes from './style/AddProject.module.scss';
import projectClasses from './style/ProjectBaseStyles.module.scss';
import Modal from '../../../components/base/Modal';
import BaseButton from '../../../components/base/BaseButton';
import Input from '../../../components/base/Input';
import TextArea from '../../../components/base/TextArea';
import Plus from '../../../assets/image/Icons/Plus.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { Employee, RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { useNavigate } from 'react-router-dom';

const projectRepository = () => RepoFactory.get(RepoType.Project);
interface MyComponentProps {
  handleNewProject?: (status: boolean) => void;
  companyId: number;
  employeesList: Employee[];
}

const AddProject = (props: MyComponentProps) => {
  const { t9n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAddProjectModal, setOpenAddProjectModal] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isNameError, setIsNameError] = useState(false);

  const ButtonIcon = () => {
    return <img src={Plus} alt="Plus" className={classes.ButtonIcon} />;
  };

  const openAddProject = () => {
    setOpenAddProjectModal(true);
    setIsNameError(false);
  };

  const nameOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setIsNameError(false);
  };

  const exitModal = () => setOpenAddProjectModal(false);
  const descriptionOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setDescription(e.currentTarget.value);

  const createProject = () => {
    if (name.length === 0) {
      setIsNameError(true);
      setLoading(false);
      return;
    } else {
      setIsNameError(false);
      setLoading(true);
      let body = {
        name: name,
        description: description,
        companyId: props.companyId,
      };
      projectRepository()
        .createProject(body)
        .then((res: AxiosResponse<any>) => {
          setLoading(false);
          exitModal();
          props.handleNewProject && props.handleNewProject(true);
          navigate(`/projects/edit/${res.data.id}`);
          setName('');
          setDescription('');
        })
        .catch((error: unknown) => {
          setLoading(false);
          if (error instanceof AxiosError) {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data));
          }
        });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <BaseButton
        title={t9n.create_new_project_btn_text}
        color="primary"
        hasLeftIcon={true}
        icon={ButtonIcon()}
        onClickHandler={openAddProject}
      />

      {openAddProjectModal && (
        <Modal
          header={t9n.create_new_project_btn_text}
          exitAction={exitModal}
          body={
            <div className={projectClasses.modalBody}>
              <Input
                name="projectName "
                placeholder={t9n.name_text}
                title={t9n.project_name_text}
                required={true}
                handleChange={nameOnChangeHandler}
                isInvalid={isNameError}
                inValidmsg={t9n.project_name_invalid_msg_text}
                autoComplete={'off'}
              />
              <TextArea
                name="description"
                placeholder={t9n.enter_description_text}
                title={t9n.description_text}
                rows={5}
                handleChange={descriptionOnChangeHandler}
              />
            </div>
          }
          action={
            <div className={projectClasses.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.create_btn_text}
                color="primary"
                onClickHandler={createProject}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default AddProject;
