import { useDispatch, useSelector } from 'react-redux';
import classes from './style/TimeSheet.module.scss';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import Calendar from '../calendar/calendar';
import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { convertTime, currentMonth, getDate, getFirstDayOfYear, getLastDayOfYear } from '../actions/timesheet';
import { IEvent, IHistory, IMetaData, INewAttendance, IStatus, IWorkTime } from '../repository/timeSheetRepository';
import BaseButton from '../../../components/base/BaseButton';
import ClockIcon from '../../../assets/image/Icons/clockIcon.svg';
import { errorActions } from '../../../store/error-slice';
import { RootState } from '../../../store';
import { RepoFactory } from '../../../baseRepository/Factory';
import { Event } from 'react-big-calendar';
import PunchOut from '../components/PunchOut/punchOut';
import { useTranslation } from '../../../providers/locale-provider';
import { UserStatus } from '../../Auth/types/type';
import Loading from '../../../components/base/Loading';
import { setPunchId, setStatus } from '../../../store/attendanceSlice';

import { getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { useNavigate } from 'react-router-dom';
import { ActionType } from '../types/types';
import { Optional, OptionalOrMissing, RepoType } from '../../../types/sharedTypes';
import { isDefined } from '../../../core/helpers/utils';

const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);

const TimeSheet = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [showPunchBtn, setShowPunchBtn] = useState<boolean>(false);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [timeIn, setTimeIn] = useState<OptionalOrMissing<string>>(null);
  const [timeOut, setTimeOut] = useState<OptionalOrMissing<string>>(null);
  const [dailyWorkTime, setDailyWorkTime] = useState<Optional<string>>(null);
  const [monthWorkingTime, setMonthWorkingTime] = useState<Optional<string>>(null);
  const [mandatoryWorkingHours, setMandatoryWorkingHours] = useState<number>(0);
  const navigate = useNavigate();
  const [punchOut, setPunchOut] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [newPunchLimit, setNewPunchLimit] = useState(0);
  const [updatePunchLimit, setUpdatePunchLimit] = useState(0);
  const [newPunchCountLimit, setNewPunchCountLimit] = useState(0);
  const [editPunchCountLimit, setEditPunchCountLimit] = useState(0);
  const [newPunchCount, setNewPunchCount] = useState(0);
  const [editPunchCount, setEditPunchCount] = useState(0);

  const attendance = useSelector((state: RootState) => state.attendanceSlice);
  const userStatus = () => {
    timeSheetRepository()
      .userStatus()
      .then(
        ({
          data: {
            result: {
              status,
              attendanceId,
              longEvent,
              first_check_in_datetime,
              last_check_out_datetime,
              new_punch_dayslimit,
              update_punch_dayslimit,
              new_punch_countlimit,
              update_punch_countlimit,
              add_count,
              edit_count,
            },
          },
        }: AxiosResponse<IStatus>) => {
          setNewPunchLimit(new_punch_dayslimit);
          setUpdatePunchLimit(update_punch_dayslimit);
          setNewPunchCountLimit(new_punch_countlimit);
          setEditPunchCountLimit(update_punch_countlimit);
          setNewPunchCount(add_count);
          setEditPunchCount(edit_count);

          if (first_check_in_datetime > 0) {
            if (checkIsToday(String(first_check_in_datetime))) {
              setTimeIn(convertTime(String(first_check_in_datetime)));
              setTimeOut(null);
              if (last_check_out_datetime > 0) {
                setTimeOut(convertTime(String(last_check_out_datetime)));
              }
            } else if (checkIsToday(String(last_check_out_datetime))) {
              setTimeIn(
                `${convertTime(String(first_check_in_datetime))} ( ${getDate(String(first_check_in_datetime))} )`
              );
              setTimeOut(convertTime(String(last_check_out_datetime)));
            } else if (!(last_check_out_datetime > 0)) {
              setTimeIn(
                `${convertTime(String(first_check_in_datetime))} ( ${getDate(String(first_check_in_datetime))} )`
              );
            } else {
              setTimeIn(null);
              setTimeOut(null);
            }
          } else {
            setTimeIn(null);
          }

          dispatch(setStatus(status));
          if (status === UserStatus.In && attendanceId) {
            dispatch(setPunchId(attendanceId));
          }
          // if (last_check_out_datetime > 0) {
          //   setTimeOut(convertTime(String(last_check_out_datetime)));
          // } else {
          //   setTimeOut(null);
          // }
        }
      );
  };

  const currentDate = getDate();
  const checkIsToday = (date: Optional<string>) => {
    if (!isDefined(date)) {
      return;
    }
    return getDate(date) === currentDate;
  };

  function convertTimeFormat(timeString: string): string {
    // Split the time string into hours and minutes
    let [hours, minutes] = timeString.split(':').map((value) => parseInt(value, 10));

    // Ensure hours and minutes are valid numbers
    if (isNaN(hours)) hours = 0;
    if (isNaN(minutes)) minutes = 0;

    // Format the time as HHh MMm
    return `${hours}h ${minutes}m`;
  }

  const getHistory = () => {
    setLoading(true);
    let params = {
      start_date: getFirstDayOfYear(),
      end_date: getLastDayOfYear(),
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    function getDurationFromStartOfDay(epochTime: number): string {
      const date = new Date(epochTime * 1000);
      const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

      // Calculate the duration in milliseconds
      const durationMs = date.getTime() - startOfDay.getTime();

      // Convert duration to hours and minutes
      const hours = Math.floor(durationMs / (1000 * 60 * 60));
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

      // Return the formatted duration as HH:MM
      return `${hours}h ${minutes}m`;
    }

    timeSheetRepository()
      .getHistory(params)
      .then(
        ({
          data: {
            result: { data },
          },
        }: AxiosResponse<IHistory>) => {
          if (data?.length) {
            const {
              check_in_datetime: checkInDate,
              check_out_datetime: checkOutDate,
              duration,
            } = data[data.length - 1];
            let epochTime;

            if (checkOutDate !== null && !checkIsToday(checkInDate) && !checkIsToday(checkOutDate)) {
              setDailyWorkTime('0h 0m');
            } else {
              if (checkOutDate !== checkInDate) {
                if (checkOutDate) {
                  epochTime = +checkOutDate;
                } else {
                  const now = new Date();
                  epochTime = Math.floor(now.getTime() / 1000);
                }
                setDailyWorkTime(getDurationFromStartOfDay(epochTime));
              } else {
                setDailyWorkTime(convertTimeFormat(duration));
              }
            }

            if (checkIsToday(checkInDate)) {
              // checkInDate is today - display check in time
              // setTimeIn(convertTime(checkInDate));
              setDailyWorkTime(convertTimeFormat(duration));
              // setTimeOut(null);
              // if (isDefined(checkOutDate)) {
              //   setTimeOut(convertTime(checkOutDate));
              // }
            } else if (checkIsToday(checkOutDate)) {
              // checkInDate is NOT today but checkOutDate is today - display check in time with date
              // setTimeIn(`${convertTime(checkInDate)} ( ${getDate(checkInDate)} )`);
              // setTimeOut(convertTime(checkOutDate));
            } else if (!isDefined(checkOutDate)) {
              // checkInDate is NOT today and checkOutDate is NOT provided - display check in time with date
              // setTimeIn(`${convertTime(checkInDate)} ( ${getDate(checkInDate)} )`);
            }
          }
          getMonthlyTime();
        }
      )
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const punchIn = () => {
    setLoading(true);
    timeSheetRepository()
      .punchIn()
      .then((result: AxiosResponse<INewAttendance>) => {
        setLoading(false);
        dispatch(setPunchId(result.data.newAttendance.id));
        getHistory();
        dispatch(setStatus(UserStatus.In));
        userStatus();
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getMonthlyTime = () => {
    let params = {
      start_date: currentMonth().start_date,
      end_date: currentMonth().end_date,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    timeSheetRepository()
      .monthlyTime(params)
      .then((result: AxiosResponse<IWorkTime>) => {
        setMonthWorkingTime(convertTimeFormat(result.data.result.formattedTotalAttendance));
        setMandatoryWorkingHours(result.data.result.mandatory_working_hours_month);
        getHistoryAttendances();
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const validateDate = (input: string | undefined) => {
    // TODO - checking string should be removed
    if (isDefined(input) && input !== 'N/A') {
      return input;
    }
    return;
  };

  const getHistoryAttendances = () => {
    let params = {
      start_date: getFirstDayOfYear(),
      end_date: getLastDayOfYear(),
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    timeSheetRepository()
      .monthlyTime(params)
      .then((result: AxiosResponse<IWorkTime>) => {
        setLoading(false);
        const data = result.data.result.allAtten;
        let formattedEvents: Event[] = [];
        if (data.length > 0) {
          const lastAttendance = data[data.length - 1];
          setStartTime(Number(lastAttendance.checkIn));
          data.forEach(async (event: IEvent) => {
            const start = new Date(+event?.checkIn * 1000);
            const end = new Date(Number(validateDate(event?.checkOut) ?? event?.checkIn) * 1000);
            const totalDuration = validateDate(event?.attendanceDur);
            const edited = event.edited ? event.edited : 0;
            const title = `${convertTime(event?.checkIn)} - ${convertTime(validateDate(event?.checkOut)) ?? '***'}`;
            const id = event.id;
            const splitTime = event.attendanceDur?.split(':');
            const isMoreThan10Hours = splitTime && Number(splitTime[0]) >= 10;
            // normal event
            if (start.getDate() === end.getDate()) {
              formattedEvents.push({
                start,
                end,
                title,
                resource: {
                  eventDate: start,
                  id,
                  type: 'in_out',
                  totalDuration,
                  edited,
                  isMoreThan10Hours,
                },
              });
            }

            // long event
            await processLongEvent({ start, end, title, id, formattedEvents, edited, isMoreThan10Hours });
          });
        }
        setEvents(formattedEvents);
        setLoading(false);
        setShowPunchBtn(true);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  interface ILongEvent {
    start: Date;
    end: Date;
    title: string;
    id: number | undefined;
    formattedEvents: Event[];
    edited: number;
    isMoreThan10Hours: boolean | undefined;
  }

  const processLongEvent = async ({
    start,
    end,
    title,
    id,
    formattedEvents,
    edited,
    isMoreThan10Hours,
  }: ILongEvent) => {
    if (start.getDate() !== end.getDate()) {
      const startHours = start.getHours();
      const startMinutes = start.getMinutes();
      const startTotalMinutes = startHours * 60 + startMinutes;

      // Calculate duration for the first day of long event
      const durationTotalMinutes = 24 * 60 - startTotalMinutes;
      const durationHours = Math.floor(durationTotalMinutes / 60);
      const durationMinutes = durationTotalMinutes % 60;

      // First day
      formattedEvents.push({
        start,
        end,
        title,
        resource: {
          eventDate: start,
          id,
          type: 'in_out',
          totalDuration: `${durationHours}h ${durationMinutes}m`,
          edited: edited,
          isMoreThan10Hours: isMoreThan10Hours,
        },
      });

      let nextDay = new Date(new Date(start).setDate(start.getDate() + 1));
      while (nextDay.getDate() !== end.getDate()) {
        // Middle day
        formattedEvents.push({
          resource: {
            eventDate: nextDay,
            totalDuration: `24h 0m`,
          },
        });
        nextDay = new Date(new Date(nextDay).setDate(nextDay.getDate() + 1));
      }

      const endHours = end.getHours();
      const endMinutes = end.getMinutes();
      // Last day
      formattedEvents.push({
        resource: {
          eventDate: nextDay,
          totalDuration: `${endHours}h ${endMinutes}m`,
          isMoreThan10Hours: isMoreThan10Hours,
        },
      });
    }
    return;
  };

  const handlePunchOut = (status: boolean) => setPunchOut(status);

  const loadNew = (state: boolean) => {
    if (state) {
      getHistory();
      userStatus();
      // getHistoryAttendances();
    }
  };
  const getTotalDuration = (events: IMetaData[], date: string) => {
    let event = events.filter((data: IMetaData) => {
      return data.date === date;
    });
    return event[0]?.duration;
  };

  const handleSelectSlot = (slotInfo: { start: Date }) => {
    const clickTime = new Date().getTime();
    if (clickTime - lastClickTime < 300) {
      const currentDate = new Date();
      const selectDate = new Date(
        slotInfo.start.getFullYear() + '-' + (slotInfo.start.getMonth() + 1) + '-' + slotInfo.start.getDate()
      );
      const today = new Date(
        currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate()
      );

      if (newPunchLimit && newPunchCount >= newPunchCountLimit) {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            state: 'error',
            message: `The number of times attendance records can be added is limited to ${newPunchCountLimit} in any ${newPunchLimit}-day period.`,
          })
        );
        return;
      }

      if (selectDate.getTime() <= today.getTime()) {
        const daysDifferenceStart = (today.getTime() - slotInfo.start.getTime()) / (1000 * 60 * 60 * 24);
        if (daysDifferenceStart <= newPunchLimit || newPunchLimit === null) {
          const data = { date: slotInfo.start, status: attendance.status, state: ActionType.Add };
          navigate('punch', { state: { data } });
        } else {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              state: 'error',
              message: `You cannot update punches that were made more than ${newPunchLimit - 1} days ago.`,
            })
          );
        }
      }
    } else {
      setLastClickTime(clickTime);
    }
  };

  const checkDaysLimit = (event: Event) => {
    const start = event.start ? new Date(event.start.getTime()) : new Date();
    const end = event.end ? new Date(event.end.getTime()) : new Date();
    const currentDate = new Date();
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Convert the difference to milliseconds and then to days
    const daysDifferenceStart = (currentDate.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
    const daysDifferenceEnd = (currentDate.getTime() - end.getTime()) / (1000 * 60 * 60 * 24);
    return daysDifferenceStart >= updatePunchLimit || daysDifferenceEnd >= updatePunchLimit;
  };

  const handleSelectEvent = (event: Event, e: React.SyntheticEvent) => {
    const isMoreThanEditLimit = checkDaysLimit(event);
    const isMoreEditCountLimit = updatePunchLimit && editPunchCountLimit && editPunchCount >= editPunchCountLimit;

    if (event.start?.getTime() !== event.end?.getTime()) {
      const data = {
        date: event.start,
        attendanceId: event.resource.id,
        status: UserStatus.In,
        state: ActionType.Edit,
        isEditDayLimit: isMoreThanEditLimit ? updatePunchLimit : null,
        isEditCountLimit: isMoreEditCountLimit ? editPunchCountLimit : null,
        isEditLimit: isMoreThanEditLimit || isMoreEditCountLimit,
        updatePunchLimit: updatePunchLimit,
      };
      navigate('punch', { state: { data } });
    }
  };

  useEffect(() => {
    getHistory();
    userStatus();
  }, [punchOut]);
  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <section className={classes.mainSection}>
        <div className={`w-100 mb-3 d-flex justify-content-end`}>
          {showPunchBtn && (
            <div className={`d-flex justify-content-end col-3`}>
              {attendance.status === UserStatus.In && startTime > 0 ? (
                <PunchOut
                  startTime={startTime}
                  punchId={attendance.punchId}
                  handlePunchOut={handlePunchOut}
                  loadnewData={loadNew}
                />
              ) : (
                <BaseButton
                  title={t9n.punch_in_btn_text}
                  hasLeftIcon={true}
                  icon={<img src={ClockIcon} alt="clockIcon" />}
                  onClickHandler={punchIn}></BaseButton>
              )}
            </div>
          )}
        </div>
        <div className={`d-flex`}>
          <div className={`${classes.cardHeader} col-3 pe-2`}>
            <div>
              <p className={`${classes.value} mb-1`}>{timeIn ?? '-'}</p>
              <p className={`${classes.title} mb-0`}>{t9n.first_punch_in}</p>
            </div>
          </div>
          <div className={`${classes.cardHeader} col-3 pe-2`}>
            <div>
              <p className={`${classes.value} mb-1`}>{timeOut ?? '-'}</p>
              <p className={`${classes.title} mb-0`}>{t9n.last_punch_out}</p>
            </div>
          </div>
          <div className={`${classes.cardHeader} col-3 pe-2`}>
            <div>
              <p className={`${classes.value} mb-1`}>{dailyWorkTime ?? '-'} / 8h</p>
              <p className={`${classes.title} mb-0`}>{t9n.Today_work_time}</p>
            </div>
          </div>
          <div className={`${classes.cardHeader} col-3`}>
            <div>
              <p className={`${classes.value} mb-1`}>
                {monthWorkingTime ?? '-'} / {mandatoryWorkingHours}h
              </p>
              <p className={`${classes.title} mb-0`}>{t9n.current_month_work_time}</p>
            </div>
          </div>
        </div>
      </section>
      <section className={`${classes.calendar} h-100`}>
        <Calendar
          status={attendance.status}
          events={events}
          views={['month']}
          defaultView="month"
          startAccessor="start"
          endAccessor="end"
          popup={true}
          showAllEvents={false}
          selectable
          onSelectSlot={handleSelectSlot}
          onDoubleClickEvent={handleSelectEvent}
          style={{ flex: 1 }}
        />
      </section>
    </DefaultLayout>
  );
};
export default TimeSheet;
