import classes from './style/Info.module.scss';
import InfoIcon from '../../assets/image/Icons/info-circle.svg';

interface IInfo {
  text: string;
  smallText?: boolean;
}

const Info: React.FC<IInfo> = ({ text, smallText= false }) => {
  return (
    <div className={`${classes.infoContainer} ${smallText ? classes.smallTxt : classes.largTxt}`}>
      <img src={InfoIcon} alt="info" />
      <div className={classes.infoTxt}>{text}</div>
    </div>
  );
};

export default Info;
